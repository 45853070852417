/* eslint-disable no-restricted-syntax */
import useCustomer from "./use-customer"

export default function useAppByPath(path) {
  const customer = useCustomer()
  const { apps = [] } = customer

  let selectedApp

  if (path) {
    for (const appGroup of apps) {
      for (const app of appGroup.apps) {
        if (app.path === path || path.startsWith(app.path)) {
          selectedApp = app
          break
        }
      }
      if (selectedApp) {
        break
      }
    }
  }

  return selectedApp
}
