import React, { useMemo, useState } from "react"
import _PropTypes from "prop-types"
import ReactPlaceholder from "react-placeholder"
import { groupBy } from "lodash"
import { IconAdjustmentsHorizontal } from "@tabler/icons-react"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useTranslation } from "react-i18next"
import { useFilterOptions, useSelectorSeletedOptions } from "./hooks"
import { Slideover } from "../ui"
import useShops from "../../hooks/use-shops"
import { mapBrandLayerName } from "../../util/brands"
import { EUR_SYMBOL } from "../../util/constants"

const filtersPlaceholder = (
  <ReactPlaceholder
    className="rounded"
    showLoadingAnimation
    style={{ width: 100, height: 32, margin: "0" }}
    type="rect"
  />
)

export const EMPTY_FILTERS = {}
const SORTED_OPTIONS_GROUPS = ["core", "category", "group"]

function AssortmentAnalysisFilters() {
  const { t } = useTranslation()
  const { selectedFilters } = useSelectorSeletedOptions()
  const { filterOptions, setFilterOptions } = useFilterOptions()
  const { data: filterOptionsData, isIdle, isFetching, isSuccess } = filterOptions
  const [open, isOpen] = useState(false)
  const { getByCode } = useShops()
  const selected = useMemo(() => selectedFilters || EMPTY_FILTERS, [selectedFilters])

  const filtersApplied = useMemo(
    () => Object.values(selected).flat().length,
    [selected],
  )

  const handleOpen = () => {
    isOpen(true)
  }

  const handleClose = () => {
    isOpen(false)
  }

  const clearFilter = (filter) => {
    const filters = { ...selected }
    delete filters[filter]
    setFilterOptions({
      filterOptions: {
        ...filters,
      },
    })
  }

  const clearFilters = () => {
    setFilterOptions(EMPTY_FILTERS)
  }

  const setOptions = (filters) => {
    setFilterOptions({
      filterOptions: {
        ...selected,
        ...filters,
      },
    })
  }

  const handleArrayAttributeChange = (name, value, checked) => {
    let newArr = selected[name] || []
    if (checked) {
      newArr = [...new Set([...(selected[name] || []), value])]
    } else {
      newArr = newArr.filter((item) => item !== value)
    }
    setOptions({
      [name]: newArr,
    })
  }

  const handleBooleanAttributeChange = (name, value, checked) => {
    let newArr = selected[name] || []
    if (checked) {
      newArr = [...new Set([...newArr, value])]
    } else {
      newArr = newArr.filter((item) => item !== value)
    }
    setOptions({
      [name]: newArr,
    })
  }

  const setMinPrice = ({ target }) => {
    const [min, max] = selected.pricing?.[0]?.split(":") || [0, 0]
    setOptions({
      pricing: [`${target.value || min}:${max}`],
    })
  }
  const setMaxPrice = ({ target }) => {
    const [min, max] = selected.pricing?.[0]?.split(":") || [0, 0]
    setOptions({
      pricing: [`${min}:${target.value || max}`],
    })
  }

  const handleMinNumberAttributeChange = (name, value) => {
    const [min, max] = selected?.[name]?.[0]?.split(":") || [0, 0]
    setOptions({
      [name]: [`${value || min}:${max}`],
    })
  }
  const handleMaxNumberAttributeChange = (name, value) => {
    const [min, max] = selected?.[name]?.[0]?.split(":") || [0, 0]
    setOptions({
      [name]: [`${min}:${value || max}`],
    })
  }

  const mapGroupName = (group) => {
    switch (group) {
      case "core":
        return t("General attributes")
      case "category":
        return t("Category attributes")
      case "group":
        return t("Group attributes")
      default:
        return group
    }
  }

  const createOptionsElements = (attributeName, type, values) => {
    if (attributeName === "pricing") {
      return (
        <>
          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              label={t("Minimum")}
              value={selected?.pricing?.[0]?.split(":")?.[0] || ""}
              placeholder="0"
              onChange={setMinPrice}
              min="0"
              type="number"
              sx={{
                textAlign: "right",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: EUR_SYMBOL,
                inputProps: { min: 0 },
              }}
            />
            <Divider variant="middle" orientation="horizontal" sx={{ width: 32 }} />
            <TextField
              size="small"
              label={t("Maximum")}
              value={selected?.pricing?.[0]?.split(":")?.[1] || ""}
              placeholder={values[0].regularSuPrice || 9999}
              onChange={setMaxPrice}
              min="0"
              type="number"
              sx={{
                textAlign: "right",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: EUR_SYMBOL,
                inputProps: { min: 0 },
              }}
            />
          </Box>
        </>
      )
    }

    const labelMapper = (value) =>
      (attributeName === "shop"
        ? getByCode(value).name
        : mapBrandLayerName(value) || "unknown"
      )
        .split("_")
        .join(" ")

    switch (type) {
      case "array":
        return (
          <FormGroup>
            {values
              .sort((a, b) => {
                const aVal = labelMapper(a)
                const bVal = labelMapper(b)
                return aVal.localeCompare(bVal, undefined, { numeric: true })
              })
              .map((val) => (
                <FormControlLabel
                  key={`${attributeName}_${val}`}
                  control={<Checkbox size="small" />}
                  onChange={({ target }) =>
                    handleArrayAttributeChange(
                      attributeName,
                      target.value,
                      target.checked,
                    )
                  }
                  sx={{
                    textTransform:
                      attributeName === "farmed_country" ? "uppercase" : "capitalize",
                  }}
                  label={t(labelMapper(val))}
                  value={val}
                  checked={selected[attributeName]?.indexOf(val) > -1}
                />
              ))}
          </FormGroup>
        )
      case "string":
        return (
          <FormGroup>
            {values
              .sort((a, b) => {
                const aVal = mapBrandLayerName(a) || "unknown"
                const bVal = mapBrandLayerName(b) || "unknown"
                return aVal.localeCompare(bVal, undefined, { numeric: true })
              })
              .map((val) => (
                <FormControlLabel
                  key={`${attributeName}_${val}`}
                  control={<Checkbox size="small" />}
                  onChange={({ target }) =>
                    handleArrayAttributeChange(
                      attributeName,
                      target.value,
                      target.checked,
                    )
                  }
                  sx={{ textTransform: "capitalize" }}
                  label={t((mapBrandLayerName(val) || "unknown").split("_").join(" "))}
                  value={val}
                  checked={selected[attributeName]?.indexOf(val) > -1}
                />
              ))}
          </FormGroup>
        )
      case "boolean":
        return (
          <FormGroup>
            <FormControlLabel
              control={<Checkbox size="small" />}
              onChange={({ target }) =>
                handleBooleanAttributeChange(attributeName, "true", target.checked)
              }
              label={t("Yes")}
              checked={selected[attributeName]?.indexOf("true") > -1}
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              onChange={({ target }) =>
                handleBooleanAttributeChange(attributeName, "false", target.checked)
              }
              label={t("No")}
              checked={selected[attributeName]?.indexOf("false") > -1}
            />
          </FormGroup>
        )
      case "number":
      case "integer":
        return (
          <Box display="flex" alignItems="center">
            <TextField
              size="small"
              label={t("Minimum")}
              value={selected[attributeName]?.[0]?.split(":")?.[0] || ""}
              placeholder="0"
              onChange={({ target }) =>
                handleMinNumberAttributeChange(attributeName, target.value)
              }
              min="0"
              type="number"
              sx={{
                textAlign: "right",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
            <Divider variant="middle" orientation="horizontal" sx={{ width: 32 }} />
            <TextField
              size="small"
              label={t("Maximum")}
              value={selected[attributeName]?.[0]?.split(":")?.[1] || ""}
              placeholder="10"
              onChange={({ target }) =>
                handleMaxNumberAttributeChange(attributeName, target.value)
              }
              min="0"
              type="number"
              sx={{
                textAlign: "right",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Box>
        )

      default:
        return t("Not Available")
    }
  }

  const clearAllAction = () =>
    filtersApplied > 0 && (
      <Button
        variant="text"
        size="small"
        data-testid="clear-button"
        onClick={clearFilters}
      >
        {t("Clear all")}
      </Button>
    )

  const options = useMemo(
    () => groupBy(filterOptionsData || [], ({ attributeType }) => attributeType),
    [filterOptionsData],
  )

  if (isIdle || isFetching) {
    return filtersPlaceholder
  }

  if (isSuccess && filterOptionsData) {
    return (
      <>
        <Box display="flex" gap={3} padding={0}>
          <Badge badgeContent={filtersApplied} color="primary">
            <Button
              size="small"
              onClick={handleOpen}
              variant="text"
              startIcon={<IconAdjustmentsHorizontal size={16} />}
              data-testid="assortment-filters-button"
            >
              {t("Filters")}
            </Button>
          </Badge>
        </Box>
        <Slideover
          title={t("Filters")}
          open={open}
          onClose={handleClose}
          actions={clearAllAction()}
          data-testid="assortment-filters-slideover"
          PaperProps={{
            sx: { minWidth: "320px" },
          }}
        >
          {SORTED_OPTIONS_GROUPS.map((group) => {
            const option = options[group]
            if (!option) return null
            return (
              <Box key={`${group}`}>
                <Container sx={{ px: "16px !important", py: 2 }}>
                  <Typography variant="body2">{mapGroupName(group)}</Typography>
                </Container>
                {option.map((filter, i) => (
                  <Accordion
                    elevation={0}
                    key={`${filter.attributeType}-${filter.attributeName}`}
                    data-testid={`assortment-filter-accordion-${filter.attributeType}-${filter.attributeName}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      id={`panel${i}-header-${filter.attributeName}`}
                      aria-controls={`panel${i}-content-${filter.attributeName}`}
                      sx={{ textTransform: "capitalize" }}
                    >
                      <Box display="flex" justifyContent="space-between" width="100%">
                        <Typography variant="body1">
                          {t(filter.attributeName?.split("_").join(" "))}
                          {selected[filter.attributeName]?.length > 0 &&
                            ` (${selected[filter.attributeName]?.length})`}
                        </Typography>

                        {selected[filter.attributeName]?.length > 0 && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation()
                              clearFilter(filter.attributeName)
                            }}
                          >
                            {t("Clear")}
                          </Button>
                        )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ maxWidth: "320px" }}>
                      {createOptionsElements(
                        filter.attributeName,
                        filter.type,
                        filter.values,
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            )
          })}
        </Slideover>
      </>
    )
  }

  return null
}

AssortmentAnalysisFilters.propTypes = {}
AssortmentAnalysisFilters.defaultProps = {}

export { AssortmentAnalysisFilters }
