import { get } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import useLocalStorage from "../../../hooks/use-local-storage"
import * as Actions from "../../../ducks/pages/assortment-analysis/action-creators"
import {
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_THRESHOLD_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

/**
 * @returns {{ threshold: string | undefined, setThreshold: ({ threshold }) => void}}
 *  the currently selected threshold and a setter function
 */
export function useThresholdFilter() {
  const dispatch = useDispatch()
  const [thresholdSettings, setThresholdSettings] = useLocalStorage(
    "assortment-analysis-settings-threshold",
    30,
  )

  const threshold = useSelector((state) =>
    get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
      ASSORTMENT_ANALYSIS_PAGE_THRESHOLD_KEY,
    ]),
  )

  function setThreshold({ threshold: newThreshold }) {
    setThresholdSettings(newThreshold)
    dispatch(Actions.setAssortmentAnalysisThresholdFilter({ threshold: newThreshold }))
  }

  return { threshold, savedThreshold: thresholdSettings, setThreshold }
}
