import React, { useState } from "react"
import PropTypes from "prop-types"
import html2canvas from "html2canvas"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { IconDownload } from "@tabler/icons-react"
import LoadingButton from "@mui/lab/LoadingButton"
import { proxyURL } from "../../util/env"

function AssortmentAnalysisExportButton({ ...props }) {
  const { t } = useTranslation()
  const [exporting, setExporting] = useState(false)

  async function handleExportClick() {
    setExporting(true)

    const element = document.querySelector(".aatoolCaptureDiv")
    element.scrollTo(0, 0)

    try {
      const canvas = await html2canvas(element, {
        imageTimeout: 300000,
        windowWidth: element.scrollWidth + 100,
        windowHeight: element.scrollHeight + 200,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        proxy: proxyURL,
      })

      const link = document.createElement("a")
      link.download = "Assortment Analysis.png"
      link.href = canvas.toDataURL()
      link.click()
      setExporting(false)
      mixpanel.track("Assortment Analysis Export Image")
    } catch (e) {
      setExporting(false)
      throw e
    }
  }

  return (
    <LoadingButton
      {...props}
      size="small"
      onClick={handleExportClick}
      color="primary"
      loading={exporting}
      startIcon={<IconDownload size={16} />}
      data-testid="assortment-export-image"
    >
      {t("Download")}
    </LoadingButton>
  )
}

AssortmentAnalysisExportButton.propTypes = {
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
}

AssortmentAnalysisExportButton.defaultProps = {
  variant: "outlined",
}

export { AssortmentAnalysisExportButton }
