import React from "react"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { Box, ToggleButton, Typography } from "@mui/material"
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined"
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined"
import { useSetMode } from "./hooks"
import { DATA_MODE, GRID_MODE } from "../../ducks/pages/assortment-analysis/constants"

function AssortmentAnalysisModeButtons() {
  const { t } = useTranslation()
  const { mode, setMode } = useSetMode()

  const handleChange = (newMode) => {
    mixpanel.track("Assortment Analysis Mode Change", { newMode })
    setMode({ mode: newMode })
  }

  return (
    <Box alignItems="center" display="flex" justifyContent="space-between">
      <Typography variant="caption" sx={{ pr: 0.5 }}>
        {t("View")}:
      </Typography>
      <ToggleButton
        color="primary"
        data-testid="assortment-data-button"
        onChange={() => handleChange(DATA_MODE)}
        selected={mode === DATA_MODE}
        size="small"
        sx={{ mr: 1, px: "9px", py: "3px" }}
        variant="text"
        value={DATA_MODE}
      >
        <TableRowsOutlinedIcon fontSize="small" /> {t("Data")}
      </ToggleButton>
      <ToggleButton
        color="primary"
        data-testid="assortment-grid-button"
        onChange={() => handleChange(GRID_MODE)}
        selected={mode === GRID_MODE}
        size="small"
        sx={{ px: "9px", py: "3px" }}
        variant="text"
        value={GRID_MODE}
      >
        <GridViewOutlinedIcon fontSize="small" /> {t("Grid")}
      </ToggleButton>
    </Box>
  )
}

export { AssortmentAnalysisModeButtons }
