import { NULL } from "../../../util/constants"
import { mergeSettings } from "../../../util/local-storage"
import {
  STATUS_REVIEW,
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_DISCARDED_BY_MOPS,
} from "../../../util/match-status"
import { allMatchTypeValues } from "../../../util/match-type"
import { validProductAvailabilities } from "../../../util/product-availability"
import { validProductFactors } from "../../../util/product-factor"
import { handleUpdateSubCategories, handleSelectCategories } from "../../category"

const validMatchStatuses = [
  NULL,
  STATUS_REVIEW,
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_DISCARDED_BY_MOPS,
]

export const handleFilterMatchesBySource = (state, action) => {
  const { sources = [] } = action

  return {
    ...state,
    selectedMatchSources: sources,
  }
}

export const handleFilterMatchesByStatus = (state, action) => {
  const { statuses = [] } = action

  return {
    ...state,
    selectedMatchStatuses: statuses.filter((status) =>
      validMatchStatuses.includes(status),
    ),
  }
}

export const handleFilterMatchesByCountry = (state, action) => {
  const { countries = [] } = action
  const { availableMatchCountries } = state

  const valid = availableMatchCountries.map(({ country }) => country)

  return {
    ...state,
    selectedMatchCountries: countries.filter(
      (c) => valid.length === 0 || valid.includes(c),
    ),
  }
}

export const handleFilterMatchesByShop = (state, action) => {
  const { shops = [] } = action
  const { availableMatchShops } = state

  const valid = availableMatchShops.map(({ shop }) => shop)

  return {
    ...state,
    selectedMatchShops: shops.filter((s) => valid.length === 0 || valid.includes(s)),
  }
}

export const handleFilterMatchesByAvailability = (state, action) => {
  const { availabilities = [] } = action

  const validAvailabilities = availabilities.filter((value) =>
    validProductAvailabilities.includes(value),
  )

  mergeSettings({
    pageReviewDefaultMatchedProductsAvailability: validAvailabilities,
  })

  return {
    ...state,
    selectedMatchProductAvailabilities: validAvailabilities,
  }
}

export const handleFilterMatchesByBrand = (state) => ({
  ...state,
})

export const handleFilterMatchesByFactor = (state, action) => {
  const { factors = [] } = action

  const validFactors = factors.filter((value) => validProductFactors.includes(value))

  return {
    ...state,
    selectedMatchProductFactors: validFactors,
  }
}

export const handleFilterMatchesByType = (state, action) => {
  const { matchType = [] } = action

  const validTypes = matchType.filter((value) => allMatchTypeValues.includes(value))

  return {
    ...state,
    selectedMatchProductTypes: validTypes,
  }
}

export const handleFilterMatchesByRefProdQuery = (state, action) => {
  const { query, source, strategy } = action

  return {
    ...state,
    refProdQuery: query,
    source,
    strategy,
  }
}

export const handleFilterMatchesByRefProdCountries = (state, action) => {
  const { countries = [] } = action

  return {
    ...state,
    selectedRefProdCountries: countries,
  }
}

export const handleFilterMatchesByRefProdFamilies = (state, action) => {
  const { families = [] } = action

  return {
    ...state,
    selectedRefProdFamilies: families,
  }
}

export const handleFilterMatchesByRefProdL1Categories = (state, action) =>
  handleSelectCategories(1, state, action)

export const handleUpdateRefProdL2Categories = (state, action) =>
  handleUpdateSubCategories(2, state, action)

export const handleFilterMatchesByRefProdL2Categories = (state, action) =>
  handleSelectCategories(2, state, action)

export const handleUpdateRefProdL3Categories = (state, action) =>
  handleUpdateSubCategories(3, state, action)

export const handleFilterMatchesByRefProdL3Categories = (state, action) =>
  handleSelectCategories(3, state, action)

export const handleUpdateRefProdL4Categories = (state, action) =>
  handleUpdateSubCategories(4, state, action)

export const handleFilterMatchesByRefProdL4Categories = (state, action) =>
  handleSelectCategories(4, state, action)
