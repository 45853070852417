import mixpanel from "mixpanel-browser"
import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Link as RRDLink } from "react-router-dom"
import styles from "./Link.module.scss"
import { isValidAnalyticsEventName } from "../../../util/user-monitoring"

function Link({
  analyticsEventName,
  analyticsEventPayload,
  children,
  className,
  onClick,
  ...props
}) {
  function handleClick(...args) {
    if (isValidAnalyticsEventName(analyticsEventName)) {
      mixpanel.track(analyticsEventName, analyticsEventPayload)
    }

    onClick(...args)
  }

  return (
    <RRDLink {...props} className={clsx(styles.link, className)} onClick={handleClick}>
      {children}
    </RRDLink>
  )
}

Link.propTypes = {
  analyticsEventName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  analyticsEventPayload: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

Link.defaultProps = {
  analyticsEventName: undefined,
  analyticsEventPayload: {},
  className: "",
  onClick: () => undefined,
}

export { Link }
