import React, { useMemo, useRef } from "react"
import clsx from "clsx"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { Icon, IconButton } from "@mui/material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useTranslation } from "react-i18next"
import { LoaderSpinner, Table } from "../../ui"

import styles from "./AssortmentAnalysisTable.module.scss"
import {
  AssortmentAnalysisArticleCountCell,
  AssortmentAnalysisArticleDataCell,
  AssortmentAnalysisColumnHeader,
  AssortmentAnalysisColumnHeaderDimension,
  AssortmentAnalysisColumnHeaderShop,
  AssortmentAnalysisRowHeader,
  NoResultsDisplay,
} from "./partials"
import {
  useAssortmentAnalysisPageMeta,
  useAssortmentAnalysisTable,
  useDimensionFilter,
  useDimensionSort,
  useFilterOptions,
  useSelectorSeletedOptions,
  useSetMode,
} from "../hooks"
import useShops from "../../../hooks/use-shops"
import { EMPTY_FILTERS } from "../AssortmentAnalysisFilters"
import { useHasAAToolShopFilter } from "../../../integrations/split"
import {
  DATA_MODE,
  DIMENSION_BRAND_TYPE,
  DIMENSION_PRICE,
} from "../../../ducks/pages/assortment-analysis/constants"
import { mapBrandLayerName, brandLayerNameMap } from "../../../util/brands"

function mapBrandLayerCount(count, translations, dimensionDirection) {
  const blCount = []
  const pinnedRows = []
  count.forEach(({ dimension, shops }) => {
    const brandLayer = mapBrandLayerName(dimension.brandLayer || "UNKNOWN")
    const currRow = {
      dimension: {
        id: brandLayer,
        ...dimension,
      },
      brandLayer,
      ...shops,
    }
    const isSame =
      blCount.findIndex((r) => r.dimension.groupId === dimension.groupId) !== -1

    if (!isSame) {
      blCount.push({
        ...currRow,
        dimension: {
          ...currRow.dimension,
          id: "",
        },
        brandLayer: "",
        subRows: [
          structuredClone({
            ...currRow,
            dimension: { ...currRow.dimension, groupName: "" },
          }),
        ],
      })
    } else {
      const idx = blCount.length - 1

      blCount[idx].subRows.push(
        structuredClone({
          ...currRow,
          dimension: { ...currRow.dimension, groupName: "" },
        }),
      )

      Object.keys(shops).forEach((shop) => {
        if (!blCount[idx][shop]) {
          blCount[idx][shop] = { ...shops[shop], count: 0 }
        }
        blCount[idx][shop].count += shops[shop].count
      })
    }
  })

  blCount.forEach((row) => {
    row.subRows.sort((a, b) =>
      dimensionDirection === "asc"
        ? a.brandLayer.localeCompare(b.brandLayer)
        : b.brandLayer.localeCompare(a.brandLayer),
    )
  })

  if (blCount.length > 1) {
    const totalFP = {}
    const totalNB = {}
    const totalPL = {}
    const totalUNK = {}
    const total = {}
    blCount.forEach((row) => {
      const { dimension, subRows } = row

      total.dimension = {
        ...dimension,
        groupName: translations.total,
        groupId: -1,
        isBold: true,
      }
      totalFP.dimension = { ...dimension, groupName: translations.totalFP, groupId: -1 }
      totalNB.dimension = { ...dimension, groupName: translations.totalNB, groupId: -1 }
      totalPL.dimension = { ...dimension, groupName: translations.totalPL, groupId: -1 }
      totalUNK.dimension = {
        ...dimension,
        groupName: translations.totalUNK,
        groupId: -1,
      }

      subRows.forEach(({ dimension: _, brandLayer, ...shops }) => {
        Object.keys(shops).forEach((shop) => {
          if (!total[shop]) {
            total[shop] = { ...shops[shop], count: 0, isTotal: true, isFinal: true }
          }
          total[shop].count += shops[shop].count

          if (!totalFP[shop]) {
            totalFP[shop] = { ...shops[shop], count: 0, isTotal: true }
          }
          if (!totalNB[shop]) {
            totalNB[shop] = { ...shops[shop], count: 0, isTotal: true }
          }
          if (!totalPL[shop]) {
            totalPL[shop] = { ...shops[shop], count: 0, isTotal: true }
          }
          if (!totalUNK[shop]) {
            totalUNK[shop] = { ...shops[shop], count: 0, isTotal: true }
          }

          if (brandLayer === brandLayerNameMap.PL1) {
            totalFP[shop].count += shops[shop].count
          } else if (brandLayer === brandLayerNameMap.NB) {
            totalNB[shop].count += shops[shop].count
          } else if (brandLayer === brandLayerNameMap.PL2) {
            totalPL[shop].count += shops[shop].count
          } else {
            totalUNK[shop].count += shops[shop].count
          }
        })
      })
    })

    blCount.push(totalFP)
    pinnedRows.push(blCount.length - 1)
    blCount.push(totalNB)
    pinnedRows.push(blCount.length - 1)
    blCount.push(totalPL)
    pinnedRows.push(blCount.length - 1)
    blCount.push(totalUNK)
    pinnedRows.push(blCount.length - 1)
    blCount.push(total)
    pinnedRows.push(blCount.length - 1)
  }

  blCount.forEach((row, idx) => {
    const { brandLayer, dimension, subRows, ...shops } = row
    Object.keys(shops).forEach((shop) => {
      if (!blCount[idx][shop].isTotal) {
        blCount[idx][shop].isParent = true
      }
    })

    subRows?.forEach(({ dimension: _, id, brandLayer: _bl, ...srShops }, i) => {
      Object.keys(srShops).forEach((shop) => {
        blCount[idx].subRows[i][shop].maxCount = row[shop].count
      })
    })
  })

  return { count: blCount, rows: pinnedRows }
}

function mapPriceCount(count, translations) {
  const pCount = []
  const pinnedRows = []
  count.forEach(({ dimension, shops }) => {
    const currRow = {
      dimension,
      price: dimension.price,
      ...shops,
    }
    const isSame =
      pCount.findIndex((r) => r.dimension.groupId === dimension.groupId) !== -1

    if (!isSame) {
      pCount.push({
        ...currRow,
        dimension: {
          ...currRow.dimension,
          start: undefined,
        },
        price: "",
        subRows: [
          structuredClone({
            ...currRow,
            dimension: { ...currRow.dimension, groupName: "" },
          }),
        ],
      })
    } else {
      const idx = pCount.length - 1

      pCount[idx].subRows.push(
        structuredClone({
          ...currRow,
          dimension: { ...currRow.dimension, groupName: "" },
        }),
      )

      Object.keys(shops).forEach((shop) => {
        if (!pCount[idx][shop]) {
          pCount[idx][shop] = { ...shops[shop], count: 0 }
        }
        pCount[idx][shop].count += shops[shop].count
      })
    }
  })

  if (pCount.length > 1) {
    const total = {}
    pCount.forEach((row) => {
      const { dimension, subRows } = row

      total.dimension = {
        ...dimension,
        groupName: translations.total,
        groupId: -1,
        isBold: true,
      }
      subRows.forEach(({ dimension: _, price, ...shops }) => {
        Object.keys(shops).forEach((shop) => {
          if (!total[shop]) {
            total[shop] = { ...shops[shop], count: 0, isTotal: true, isFinal: true }
          }
          total[shop].count += shops[shop].count
        })
      })
    })
    pCount.push(total)
    pinnedRows.push(pCount.length - 1)
  }

  // set the max count for each shop in each subrow
  pCount.forEach((row, idx) => {
    const { dimension, price, subRows, ...shops } = row
    Object.keys(shops).forEach((shop) => {
      if (!pCount[idx][shop].isTotal) {
        pCount[idx][shop].isParent = true
      }
    })
    subRows?.forEach(({ dimension: _, price: _p, ...pShops }, i) => {
      Object.keys(pShops).forEach((shop) => {
        pCount[idx].subRows[i][shop].maxCount = row[shop].count
      })
    })
  })

  return { count: pCount, rows: pinnedRows }
}

export function AssortmentAnalysisTable() {
  const { t } = useTranslation()
  const hasShopFilterEnabled = useHasAAToolShopFilter()
  const shouldFilterShop = hasShopFilterEnabled || false
  const { setFilterOptions } = useFilterOptions()
  const {
    data: { articles, count } = {},
    isSuccess,
    isRefetching,
  } = useAssortmentAnalysisTable()
  const { hasArticles, hasCount, hasShops } = useAssortmentAnalysisPageMeta()
  const { dimension: dimensionFilter } = useDimensionFilter()
  const { dimensionDirection } = useDimensionSort()
  const {
    selectedFilters: { shop: selectedShop },
  } = useSelectorSeletedOptions()
  const { getByCode, items } = useShops()
  const { mode } = useSetMode()
  const translations = useMemo(
    () => ({
      brandLayer: t("Brand Layer"),
      price: t("Price"),
      total: t("Total"),
      totalFP: t("Total first price"),
      totalNB: t("Total national brand"),
      totalPL: t("Total private label"),
      totalUNK: t("Total unknown"),
    }),
    [t],
  )

  const pinnedRows = useRef([])

  const isDataMode = mode === DATA_MODE

  const isNested =
    isDataMode &&
    (dimensionFilter === DIMENSION_BRAND_TYPE || dimensionFilter === DIMENSION_PRICE)
  const isPrice = dimensionFilter === DIMENSION_PRICE

  const columnGridData = useMemo(() => {
    if ((isSuccess || isRefetching) && articles) {
      return articles.map(({ dimension, shops }) => ({ dimension, ...shops }))
    }

    return null
  }, [articles, isRefetching, isSuccess])

  const columnCountData = useMemo(() => {
    if ((isSuccess || isRefetching) && Array.isArray(count)) {
      if (count.length === 0) {
        return []
      }

      if (isNested) {
        let nestedMappedData = []
        if (isPrice) {
          const { count: priceCount, rows } = mapPriceCount(count, translations)
          nestedMappedData = priceCount
          pinnedRows.current = rows
        } else {
          const { count: brandLayerCount, rows } = mapBrandLayerCount(
            count,
            translations,
            dimensionDirection,
          )
          nestedMappedData = brandLayerCount
          pinnedRows.current = rows
        }

        return nestedMappedData
      }

      const mappedData = count.map(({ dimension, shops }) => ({ dimension, ...shops }))
      const total = {}
      mappedData.forEach((row) => {
        const { dimension, ...shops } = row
        total.dimension = {
          ...dimension,
          groupName: translations.total,
          groupId: -1,
          isBold: true,
        }
        Object.keys(shops).forEach((shop) => {
          if (!total[shop]) {
            total[shop] = { ...shops[shop], count: 0, isTotal: true, isFinal: true }
          }
          total[shop].count += shops[shop].count
        })
      })

      if (mappedData.length > 1) {
        mappedData.push(total)

        // set the max count for each shop in each row
        mappedData.forEach((row, idx) => {
          const { dimension, ...shops } = row
          Object.keys(shops).forEach((shop) => {
            mappedData[idx][shop].maxCount = total[shop].count
          })
        })

        pinnedRows.current.push(mappedData.length - 1)
      }
      return mappedData
    }

    return null
  }, [
    isSuccess,
    isRefetching,
    count,
    isNested,
    isPrice,
    translations,
    dimensionDirection,
  ])

  const columnData = isDataMode ? columnCountData : columnGridData

  const columnDefs = useMemo(() => {
    if (Array.isArray(columnData)) {
      if (columnData.length === 0) {
        return []
      }

      const filterSelectedShops = (shopColumns) => {
        const shopsToKeep = shopColumns.map((shopColumn) => {
          const { dimension, brandLayer, price, subRows, ...shopList } = shopColumn
          return Object.fromEntries(
            Object.entries(shopList).filter(([shop]) => {
              if (
                !shouldFilterShop &&
                !(Array.isArray(selectedShop) && selectedShop.length > 0)
              ) {
                return true
              }
              if (Array.isArray(selectedShop) && selectedShop.length > 0) {
                return selectedShop.includes(shopList[shop].shop)
              }
              if (shopList[shop]?.articles?.length) {
                return shopList[shop].articles.length > 0
              }
              return shopList[shop]?.count
            }),
          )
        })
        const shops = Object.assign({}, ...new Set(shopsToKeep))
        const sortedShops = Object.entries(shops).sort((a, b) => {
          const shopA = getByCode(a[1].shop)
          const shopB = getByCode(b[1].shop)

          if (shopA.name && shopB.name) {
            return shopA.name.localeCompare(shopB.name)
          }
          return false
        })

        const startShops = Object.keys(items).filter((shop) => items[shop].isOwnShop)
        if (startShops.length > 0) {
          // sort the shops and reverse the order to insert them at the beginning alphabetically
          const startShopsSorted = startShops
            .sort((a, b) => {
              const shopA = getByCode(a)
              const shopB = getByCode(b)

              if (shopA.name && shopB.name) {
                return shopA.name.localeCompare(shopB.name)
              }
              return false
            })
            .reverse()
          startShopsSorted.forEach((startShop) => {
            const idx = sortedShops.findIndex((shop) => shop[1].shop === startShop)
            if (idx !== -1) {
              sortedShops.unshift(sortedShops.splice(idx, 1)[0])
            }
          })
        }

        return sortedShops
      }

      const shops = filterSelectedShops(columnData)

      const dimensionSize = isNested ? 340 : 290
      const shopsSize = isDataMode ? 122 : 314
      const defs = [
        {
          accessorKey: "dimension",
          header: (h) => (
            <Box
              alignItems="center"
              display="inline-flex"
              justifyContent="flex-start"
              height="100%"
              width="100%"
            >
              {isNested ? (
                <Button
                  aria-label="expand"
                  onClick={h.table.getToggleAllRowsExpandedHandler()}
                  size="small"
                  variant="text"
                  sx={{ mx: 1, height: "56px", width: "56px" }}
                >
                  {h.table.getIsAllRowsExpanded() ? (
                    <ExpandMoreIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </Button>
              ) : (
                ""
              )}
              <AssortmentAnalysisColumnHeader>
                <AssortmentAnalysisColumnHeaderDimension
                  dimensionType={dimensionFilter}
                  mode={mode}
                />
              </AssortmentAnalysisColumnHeader>
            </Box>
          ),
          cell: (c) => (
            <Box
              alignItems="center"
              display="inline-flex"
              justifyContent="flex-start"
              height="100%"
              width="100%"
            >
              {c.row.getCanExpand() ? (
                <Button
                  aria-label="expand"
                  onClick={c.row.getToggleExpandedHandler()}
                  size="small"
                  variant="text"
                  sx={{ mx: 1, height: "56px", width: "56px" }}
                >
                  {c.row.getIsExpanded() ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </Button>
              ) : (
                ""
              )}
              <AssortmentAnalysisRowHeader dimension={c.getValue()} mode={mode} />
            </Box>
          ),
          size: dimensionSize,
          minSize: dimensionSize,
          footer: null,
        },
        ...shops.map(([shopCountryCode, { country, shop }]) => ({
          accessorKey: shopCountryCode,
          header: (h) => (
            <Box
              display="inline-flex"
              alignItems="center"
              width={shopsSize}
              sx={{
                ".pin": {
                  visibility: "hidden",
                },
                ":hover .pin": {
                  visibility: "visible",
                },
              }}
            >
              <AssortmentAnalysisColumnHeader>
                <AssortmentAnalysisColumnHeaderShop
                  id={shop}
                  country={country}
                  mode={mode}
                />
                {h.column.getCanPin() && (
                  <Box className="pin">
                    {h.column.getIsPinned() !== "left" ? (
                      <IconButton
                        size="small"
                        onClick={() => {
                          h.column.pin("left")
                        }}
                      >
                        <Icon className="material-symbols-outlined">keep</Icon>
                      </IconButton>
                    ) : null}
                    {h.column.getIsPinned() ? (
                      <IconButton
                        size="small"
                        onClick={() => {
                          h.column.pin(false)
                        }}
                      >
                        <Icon className="material-symbols-outlined">keep_off</Icon>
                      </IconButton>
                    ) : null}
                  </Box>
                )}
              </AssortmentAnalysisColumnHeader>
            </Box>
          ),
          cell: (c) =>
            isDataMode ? (
              <AssortmentAnalysisArticleCountCell data={c.getValue()} />
            ) : (
              <AssortmentAnalysisArticleDataCell articles={c.getValue().articles} />
            ),
          size: shopsSize,
          minSize: shopsSize,
          footer: null,
        })),
      ]

      if (isNested) {
        const nestedSize = 200
        defs.splice(1, 0, {
          id:
            dimensionFilter === DIMENSION_BRAND_TYPE
              ? "nestedBrandLayer"
              : "nestedPrices",
          accessorKey: "dimension",
          header: () => (
            <Box display="inline-flex" width={nestedSize}>
              <AssortmentAnalysisColumnHeader>
                <AssortmentAnalysisColumnHeaderDimension
                  dimensionType={dimensionFilter}
                />
              </AssortmentAnalysisColumnHeader>
            </Box>
          ),
          cell: (c) => {
            if (c.getValue()?.id) {
              return <AssortmentAnalysisRowHeader dimension={c.getValue()} />
            }
            return null
          },
          size: nestedSize,
          minSize: nestedSize,
          footer: null,
        })
      }

      return defs
    }

    return null
  }, [
    columnData,
    isNested,
    isDataMode,
    items,
    shouldFilterShop,
    selectedShop,
    getByCode,
    dimensionFilter,
    mode,
  ])

  if (columnData === null || columnDefs === null) {
    return <LoaderSpinner />
  }

  let overscan = 50
  if (columnData) {
    if (isNested) {
      overscan =
        columnData.length * columnData.find((d) => d.subRows)?.subRows.length + 50
    } else {
      overscan = columnData.length + 50
    }
  }

  if (isRefetching) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100vh"
        justifyContent="center"
      >
        <LoaderSpinner />
      </Box>
    )
  }

  if (
    ((!hasArticles || !columnData.length) && !isDataMode) ||
    ((!hasCount || !columnData.length) && isDataMode)
  ) {
    return (
      <Box
        data-testid="no-results"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <NoResultsDisplay src="/assets/images/undraw_empty_re_opql.svg">
          {t(`It the appears we have no articles to display, using the currently active
          criteria (filters).`)}
        </NoResultsDisplay>
        <Button
          variant="text"
          size="small"
          color="primary"
          onClick={() => setFilterOptions(EMPTY_FILTERS)}
        >
          {t("Clear all filters")}
        </Button>
      </Box>
    )
  }

  if (!hasShops) {
    return (
      <NoResultsDisplay src="/assets/images/undraw_empty_re_opql.svg">
        {t(`It the appears we have no shops to display, using the currently active criteria
        (filters).`)}
      </NoResultsDisplay>
    )
  }

  return (
    <div className={styles.container}>
      <Table.Root
        className={clsx("aatoolCaptureDiv", "grow", styles.table)}
        columns={columnDefs}
        data={columnData}
        data-testid="assortment-analysis-table"
        pinnedColumns={[
          "dimension",
          dimensionFilter === DIMENSION_BRAND_TYPE
            ? "nestedBrandLayer"
            : "nestedPrices",
        ]}
        pinnedRows={isDataMode ? pinnedRows.current.map((r) => r.toString()) : []}
        stickyXHeader
        stickyYHeader
        stripedRows
        xHeaderId="dimension"
        overscan={overscan}
        cellPad={false}
        rowStyle="rowDark"
        style={{ "height": "calc(100% - 32px)" }}
      />
    </div>
  )
}
