import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { useDataFilters } from "../../hooks"
import {
  DataFilterFormatOptions,
  DataFilterValueOptions,
} from "../../../../ducks/pages/assortment-analysis/constants"

const Container = ({ children, bgColor = "transparent" }) => (
  <Box
    alignItems="center"
    backgroundColor={bgColor}
    display="flex"
    justifyContent="flex-end"
    height="100%"
    width="100%"
  >
    {children}
  </Box>
)

function AssortmentAnalysisArticleCountCell({ data }) {
  const { dataFilter } = useDataFilters()

  const isParent = data?.isParent || false
  const isTotal = data?.isTotal || false
  const isFinal = data?.isFinal || false
  const isPercentage = dataFilter.value === DataFilterValueOptions[1].id
  const isColor = dataFilter.format === DataFilterFormatOptions[1].id
  const isColorDuo = dataFilter.format === DataFilterFormatOptions[2].id

  if (!isParent && !isTotal && (!data || !data.count || data.count === 0)) {
    return (
      <Container>
        <Box
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            height: "100%",
            width: "100%",
          }}
        />
      </Container>
    )
  }

  let count = isParent ? `(${data.count})` : data.count
  const { maxCount } = data

  if (isPercentage && !isParent && !isTotal) {
    count = `${((data.count / maxCount) * 100).toFixed(2)}%`
  }

  const green = (transparency) => `rgba(15, 177, 162, ${transparency})`
  const red = (transparency) => `rgba(209, 45, 26, ${transparency})`

  let color = "transparent"
  if (!isParent && !isTotal && isColor) {
    color = green(data.count / maxCount)
  }
  if (!isParent && !isTotal && isColorDuo) {
    const p = (data.count / maxCount).toFixed(2)
    if (p < 0.5) {
      color = red(1 - p)
    } else {
      color = green(p)
    }
  }

  const textStyle = isParent || (isTotal && isFinal) ? { fontWeight: 700 } : {}

  return (
    <Container bgColor={color}>
      <Typography variant="body1" sx={{ p: 2, ...textStyle }}>
        {count}
      </Typography>
    </Container>
  )
}

AssortmentAnalysisArticleCountCell.propTypes = {
  data: PropTypes.shape({
    count: PropTypes.number,
    isFinal: PropTypes.bool,
    isParent: PropTypes.bool,
    isTotal: PropTypes.bool,
    maxCount: PropTypes.number,
  }),
}
AssortmentAnalysisArticleCountCell.defaultProps = {
  data: {
    count: 0,
    isFinal: false,
    isParent: false,
    isTotal: false,
    maxCount: 0,
  },
}

export { AssortmentAnalysisArticleCountCell }
