import {
  REF_PRODUCT_LIST,
  REF_PRODUCT_GET_BY_IDS,
  REF_PRODUCT_FILTER_BY_STATUS,
  REF_PRODUCT_FILTER_BY_ID,
  REF_PRODUCT_FILTER_BY_QUERY,
  REF_PRODUCT_FILTER_BY_COUNTRY,
  REF_PRODUCT_FILTER_BY_FAMILY,
  REF_PRODUCT_START_SELECT,
  REF_PRODUCT_SELECT,
  REF_PRODUCT_SELECT_ERROR,
  REF_PRODUCT_UPDATE_CONTENT,
  REF_PRODUCT_USE_FALLBACK_IMAGE,
  REF_PRODUCT_LIST_EXISTING_MATCHES,
  REF_PRODUCT_CLEAR_CACHE,
  // REF_PRODUCT_MATCH,
  REF_PRODUCT_SELECT_MATCHED_SHOPS,
  REF_PRODUCT_SELECT_MATCHED_BRANDS,
  REF_PRODUCT_SET_SHOW_DISCARDED,
  REF_PRODUCT_SELECT_MATCHED_COUNTRIES,
  REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS,
  REF_PRODUCT_SUMMARY,
  REF_PRODUCT_SORT_BY,
  REF_PRODUCT_TRIGGER_LIST,
  REF_PRODUCT_START_FILTER_BY_L1_CATEGORIES,
  REF_PRODUCT_FILTER_BY_L1_CATEGORIES,
  REF_PRODUCT_START_FILTER_BY_L2_CATEGORIES,
  REF_PRODUCT_FILTER_BY_L2_CATEGORIES,
  REF_PRODUCT_UPDATE_L2_CATEGORIES,
  REF_PRODUCT_START_FILTER_BY_L3_CATEGORIES,
  REF_PRODUCT_FILTER_BY_L3_CATEGORIES,
  REF_PRODUCT_UPDATE_L3_CATEGORIES,
  REF_PRODUCT_START_FILTER_BY_L4_CATEGORIES,
  REF_PRODUCT_FILTER_BY_L4_CATEGORIES,
  REF_PRODUCT_UPDATE_L4_CATEGORIES,
  REF_PRODUCT_CLEAR_LIST,
  REF_PRODUCT_GET_CATEGORIES,
  REF_PRODUCT_SELECT_MATCHED_PRODUCTS_AVAILABILITY,
  REF_PRODUCT_SELECT_MATCHED_PRODUCTS_FACTOR,
  REF_PRODUCT_SELECT_MATCHED_PRODUCTS_TYPE,
} from "./action-types"

// Action Creators

// summary

export const getReferenceProductsSummary = () => ({
  type: REF_PRODUCT_SUMMARY,
})

// categories

export const getReferenceProductCategories = () => ({
  type: REF_PRODUCT_GET_CATEGORIES,
})

// trigger list

export const triggerListReferenceProducts = (page) => ({
  type: REF_PRODUCT_TRIGGER_LIST,
  page,
})

// list
export const listReferenceProducts = (page = 1, listName = "list") => ({
  type: REF_PRODUCT_LIST,
  page,
  listName,
  silentError: listName !== "list",
})

// list auto-complete suggestions
export const listReferenceProductAutoCompleteSuggestions = (
  query,
  limit,
  source = "all",
) => ({
  type: REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS,
  query,
  limit,
  source,
})

// clear cache
export const clearReferenceProductsCache = () => ({
  type: REF_PRODUCT_CLEAR_CACHE,
})

// detail products by ids
export const getProductsByIds = ({ ids, message = "", preLoadImages = false }) => ({
  type: REF_PRODUCT_GET_BY_IDS,
  ids,
  message,
  preLoadImages,
})

// sort by
export const sortReferenceProductsBy = (field, options = {}) => ({
  type: REF_PRODUCT_SORT_BY,
  field,
  options,
})

// filter by status
export const filterReferenceProductsStatus = (status, options = {}) => ({
  type: REF_PRODUCT_FILTER_BY_STATUS,
  status,
  options,
})

// filter by country
export const filterReferenceProductsByCountry = (countries = [], options = {}) => ({
  type: REF_PRODUCT_FILTER_BY_COUNTRY,
  countries,
  options,
})

// filter by family
export const filterReferenceProductsByFamily = (families = [], options = {}) => ({
  type: REF_PRODUCT_FILTER_BY_FAMILY,
  families,
  options,
})

// filter by query
export const filterReferenceProductsByQuery = (query, options = {}) => ({
  type: REF_PRODUCT_FILTER_BY_QUERY,
  query,
  options,
  source: options.source,
  strategy: options.strategy,
  modeChanged: options.modeChanged,
  strategyChanged: options.strategyChanged,
})

// filter by ID
export const filterReferenceProductsById = (id, options = {}) => ({
  type: REF_PRODUCT_FILTER_BY_ID,
  id,
  options,
})

// start filter by L1 categories
export const startFilterReferenceProductsByL1Categories = (
  categories = [],
  options = {},
) => ({
  type: REF_PRODUCT_START_FILTER_BY_L1_CATEGORIES,
  categories,
  options,
})

// filter by L1 categories
export const filterReferenceProductsByL1Categories = (
  categories = [],
  tree,
  options = {},
) => ({
  type: REF_PRODUCT_FILTER_BY_L1_CATEGORIES,
  categories,
  tree,
  options,
})

export const startFilterReferenceProductsByL2Categories = (
  categories = [],
  options = {},
) => ({
  type: REF_PRODUCT_START_FILTER_BY_L2_CATEGORIES,
  categories,
  options,
})

export const filterReferenceProductsByL2Categories = (
  categories = [],
  options = {},
) => ({
  type: REF_PRODUCT_FILTER_BY_L2_CATEGORIES,
  categories,
  options,
})

export const updateReferenceProductsL2Categories = (tree, options = {}) => ({
  type: REF_PRODUCT_UPDATE_L2_CATEGORIES,
  tree,
  options,
})

export const startFilterReferenceProductsByL3Categories = (
  categories = [],
  options = {},
) => ({
  type: REF_PRODUCT_START_FILTER_BY_L3_CATEGORIES,
  categories,
  options,
})

export const filterReferenceProductsByL3Categories = (
  categories = [],
  options = {},
) => ({
  type: REF_PRODUCT_FILTER_BY_L3_CATEGORIES,
  categories,
  options,
})

export const updateReferenceProductsL3Categories = (tree, options = {}) => ({
  type: REF_PRODUCT_UPDATE_L3_CATEGORIES,
  tree,
  options,
})

export const startFilterReferenceProductsByL4Categories = (
  categories = [],
  options = {},
) => ({
  type: REF_PRODUCT_START_FILTER_BY_L4_CATEGORIES,
  categories,
  options,
})

export const filterReferenceProductsByL4Categories = (
  categories = [],
  options = {},
) => ({
  type: REF_PRODUCT_FILTER_BY_L4_CATEGORIES,
  categories,
  options,
})

export const updateReferenceProductsL4Categories = (tree, options = {}) => ({
  type: REF_PRODUCT_UPDATE_L4_CATEGORIES,
  tree,
  options,
})

export const clearReferenceProductsList = () => ({
  type: REF_PRODUCT_CLEAR_LIST,
})

// select
export const startSelectReferenceProduct = (idx = 0, options = {}) => ({
  type: REF_PRODUCT_START_SELECT,
  idx,
  options,
})

// select
export const selectReferenceProduct = (idx = 0, options = {}) => ({
  type: REF_PRODUCT_SELECT,
  idx,
  options,
})

// select error
export const throwInvalidProductSelected = (error) => ({
  type: REF_PRODUCT_SELECT_ERROR,
  error,
})

// update content
export const updateProductContent = (productId, multiplier, value, unit) => ({
  type: REF_PRODUCT_UPDATE_CONTENT,
  productId,
  multiplier,
  value,
  unit,
})

// select
export const useFallbackImage = (productId) => ({
  type: REF_PRODUCT_USE_FALLBACK_IMAGE,
  productId,
})

// list existing matches
export const listExistingMatches = (id, options) => ({
  type: REF_PRODUCT_LIST_EXISTING_MATCHES,
  id,
  options,
})

// select matched shops
export const selectMatchedShops = (selected) => ({
  type: REF_PRODUCT_SELECT_MATCHED_SHOPS,
  selected,
})

// select matched brands
export const selectMatchedBrands = (selected) => ({
  type: REF_PRODUCT_SELECT_MATCHED_BRANDS,
  selected,
})

// select matched countries
export const selectMatchedCountries = (selected) => ({
  type: REF_PRODUCT_SELECT_MATCHED_COUNTRIES,
  selected,
})

// set show discarded matches status
export const setShowDiscardMatches = (show) => ({
  type: REF_PRODUCT_SET_SHOW_DISCARDED,
  show,
})

// selected matched product availability
export const selectMatchedProductsAvailability = (selected) => ({
  type: REF_PRODUCT_SELECT_MATCHED_PRODUCTS_AVAILABILITY,
  selected,
})

export const selectMatchedProductsFactor = (selected) => ({
  type: REF_PRODUCT_SELECT_MATCHED_PRODUCTS_FACTOR,
  selected,
})

export const selectMatchedProductsType = (selected) => ({
  type: REF_PRODUCT_SELECT_MATCHED_PRODUCTS_TYPE,
  selected,
})
