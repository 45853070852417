import { useDispatch } from "react-redux"
import { getValuesOfSearchParams } from "../../../../ducks/pages/matches-overview/helpers"
import { toLevelArrays } from "../use-matches-overview-reference-product-categories/to-level-arrays"
import { getRefProdAutoCompleteSuggestions } from "../../../../api/service"
import useMatchesOverviewFilters from "../use-matches-overview-filters"
import { MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY } from "../../../../ducks/pages/matches-overview/constants"
import {
  clearFilterReferenceProductSearch,
  setFilterReferenceProductSearch,
} from "../../../../ducks/pages/matches-overview/action-creators"

export function useMatchesOverviewReferenceProductSearch({
  appliedCategories,
  appliedFamilies,
}) {
  const dispatch = useDispatch()

  const facet = useMatchesOverviewFilters([
    MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
    "searchFacet",
  ])

  const term = useMatchesOverviewFilters([
    MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
    "searchTerm",
  ])

  const strategy = useMatchesOverviewFilters([
    MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
    "searchStrategy",
  ])

  function clearSearch() {
    dispatch(clearFilterReferenceProductSearch())
  }

  function runSearch(value) {
    dispatch(
      setFilterReferenceProductSearch({
        searchFacet: value.type,
        searchTerm: value.title,
        searchStrategy: value.strategy,
      }),
    )
  }

  /**
   * @see https://headlessui.com/react/combobox#allowing-empty-values
   */
  function selectSearch(value) {
    if (value === null) {
      if (term !== "") {
        clearSearch()
      }

      return
    }

    if (value.type !== facet || value.title !== term || value.strategy !== strategy) {
      runSearch(value)
    }
  }

  async function getSuggestions(query) {
    const payload = { limit: 10, query }

    if (appliedCategories.length > 0) {
      Object.assign(payload, toLevelArrays(getValuesOfSearchParams(appliedCategories)))
    }

    if (appliedFamilies.length > 0) {
      Object.assign(payload, { families: getValuesOfSearchParams(appliedFamilies) })
    }

    const {
      products: { articleNrs, brands, eans, names },
    } = await getRefProdAutoCompleteSuggestions(payload)

    const suggestions = [
      ...articleNrs.map((value) => ({ type: "ref", title: value })),
      ...eans.map((value) => ({ type: "ean", title: value })),
      ...names.map((value) => ({ type: "name", title: value })),
      ...brands.map((value) => ({ type: "brand", title: value })),
    ]

    return suggestions.sort()
  }

  return {
    getSuggestions,
    clearSearch,
    currentSearch: { type: facet, title: term },
    selectSearch,
  }
}
