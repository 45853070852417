import { get } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import * as Actions from "../../../ducks/pages/assortment-analysis/action-creators"
import {
  ASSORTMENT_ANALYSIS_PAGE_DATA_FILTER_KEY,
  ASSORTMENT_ANALYSIS_PAGE_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

/**
 * @returns {{ dataFilter: { value: string, format: string } | undefined, setDataFilter: ({ dataFilter }) => void}}
 *  the currently selected dataFilter and a setter function
 */
export function useDataFilters() {
  const dispatch = useDispatch()

  const dataFilter = useSelector((state) =>
    get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_DATA_FILTER_KEY,
    ]),
  )

  function setDataFilter({ dataFilter: newFilter }) {
    dispatch(Actions.setAssortmentAnalysisDataFilter({ dataFilter: newFilter }))
  }

  return { dataFilter, setDataFilter }
}
