import React, { useState, useEffect, useMemo, useCallback } from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import debounce from "lodash/debounce"

import mixpanel from "mixpanel-browser"
import { actionCreators as matchActions } from "../../ducks/matches"
import { actionCreators as refProdActions } from "../../ducks/reference-products"

import { PRODUCT_SUGGESTIONS_LIMIT, NULL } from "../../util/constants"
import { Logger } from "../../util/log"
import MatchesReviewFilterBarPresenter from "./MatchesReviewFilterBarPresenter"
import MatchesReviewFilterBarFallbackRenderer from "./MatchesReviewFilterBarFallbackRenderer"
import { STATUS_APPROVED, STATUS_REVIEW } from "../../util/match-status"
import { getMatchSourceFilterOptions } from "../../util/match-source"
import { withErrorBoundary } from "../../hocs/error-boundary"
import { defaultProductAvailabilityValues } from "../../util/product-availability"
import { defaultProductFactorValues } from "../../util/product-factor"
import { allMatchTypeValues } from "../../util/match-type"

const log = new Logger("ui:MatchesReviewFilterBarContainer")

let searchTermChanged = false

function preSuggest(action, searchChanged, term, limit) {
  if (searchChanged) {
    action(term, limit)
  }
}

const debouncedListSuggestions = debounce(preSuggest, 500)

function MatchesReviewFilterBar({
  totalReferenceProductsCount,
  statusSummary,
  validMatchSources,
  selectedMatchSources,
  selectedMatchStatuses,
  availableMatchCountries,
  selectedMatchCountries,
  availableMatchShops,
  selectedMatchShops,
  selectedMatchProductAvailabilities,
  selectedMatchProductFactors,
  selectedMatchProductTypes,
  countries: availableRefProdCountries,
  families: availableRefProdFamilies,
  selectedRefProdCountries,
  selectedRefProdFamilies,
  refProdQuery,
  autoCompleteSuggestions,
  reviewPageSettings,
  matchesList,
  // actions
  triggerlistMatches,
  listReferenceProductAutoCompleteSuggestions,
  filterMatchesBySource,
  filterMatchesByStatus,
  startFilterMatchesByRefProdCountries,
  startFilterMatchesByRefProdFamilies,
  filterMatchesByCountry,
  filterMatchesByShop,
  filterMatchesByAvailability,
  filterMatchesByFactor,
  filterMatchesByType,
  filterMatchesByRefProdQuery,
  setMatchesReviewPageSettings,
  startFilterMatchesByRefProdL1Categories,
  startFilterMatchesByRefProdL2Categories,
  startFilterMatchesByRefProdL3Categories,
  startFilterMatchesByRefProdL4Categories,
}) {
  const { showFilters } = reviewPageSettings

  const [searchTerm, setSearchTerm] = useState(refProdQuery || "")

  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (firstLoad && matchesList.length === 0) {
      triggerlistMatches()
      setFirstLoad(false)
    }
  }, [firstLoad, matchesList, triggerlistMatches])

  let allMatchesShortcutText = "All Matches"
  let approvedMatchesShortcutText = "Approved Matches"
  let inReviewMatchesShortcutText = "Matches to Review"
  if (statusSummary) {
    const { in_review: review, approved } = statusSummary

    const refProdSummary = totalReferenceProductsCount
      ? `<strong>${totalReferenceProductsCount}</strong> Reference Product${
          totalReferenceProductsCount === 1 ? "" : "s"
        } with `
      : ""

    allMatchesShortcutText = `${refProdSummary}<strong>${
      review + approved
    }</strong> Match${review + approved === 1 ? "" : "es"}`
    approvedMatchesShortcutText = `<strong>${approved}</strong> Approved Match${
      approved === 1 ? "" : "es"
    }`
    inReviewMatchesShortcutText = `<strong>${review}</strong> Match${
      review === 1 ? "" : "es"
    } need to be approved`
  }

  const matchSourceOptionsList = useMemo(
    () => getMatchSourceFilterOptions(validMatchSources),
    [validMatchSources],
  )

  function handleRefProdSearchTermChange(_, { newValue, method }) {
    if (method !== "enter") {
      setSearchTerm(newValue)
      searchTermChanged = true
    }
  }

  function handleRefProdCountryFilterChange({ target: { value } }) {
    log.debug("changing ref prod country filter. value:", value)
    startFilterMatchesByRefProdCountries(value)
  }

  function handleRefProdFamilyFilterChange({ target: { value } }) {
    log.debug("changing ref prod family filter. value:", value)
    startFilterMatchesByRefProdFamilies(value)
  }

  function handleSuggestionsFetchRequested({ value }) {
    debouncedListSuggestions(
      listReferenceProductAutoCompleteSuggestions,
      searchTermChanged,
      value,
      PRODUCT_SUGGESTIONS_LIMIT,
    )
  }

  function handleSuggestionsClearRequested() {
    if (refProdQuery) {
      filterMatchesByRefProdQuery()
    }
    searchTermChanged = false
    debouncedListSuggestions.cancel()
  }

  function handleSuggestionSelected(
    _,
    { suggestionValue, source, strategy, modeChanged, strategyChanged },
  ) {
    log.debug("suggestionValue:", suggestionValue)

    searchTermChanged = false
    debouncedListSuggestions.cancel()

    if (refProdQuery !== suggestionValue || modeChanged || strategyChanged) {
      filterMatchesByRefProdQuery(suggestionValue, { source, strategy })
    }
  }

  function handleMatchSourceFilterChange({ target: { value } }) {
    log.debug("changing source filter. value:", value)
    filterMatchesBySource(value)
  }

  function handleMatchStatusFilterChange({ target: { value } }) {
    log.debug("changing status filter. value:", value)
    const finalValue = value.length ? value : [NULL]
    filterMatchesByStatus(finalValue)
  }

  function handleMatchCountryFilterChange({ target: { value } }) {
    log.debug("changing country filter. value:", value)
    filterMatchesByCountry(value)
  }

  function handleMatchShopFilterChange({ target: { value } }) {
    log.debug("changing shop filter. value:", value)
    filterMatchesByShop(value)
  }

  function handleMatchAvailabilityFilterChange({ target: { value } }) {
    log.debug("changing availability filter. value:", value)
    filterMatchesByAvailability(value)
  }

  function handleMatchFactorFilterChange({ target: { value } }) {
    log.debug("changing factor filter. value:", value)
    filterMatchesByFactor(value)
  }

  function handleMatchProductTypeFilterChange({ target: { value } }) {
    log.debug("changing match type filter. value:", value)
    filterMatchesByType(value)
  }

  const doSingleFilter = useCallback(
    ({ statuses } = {}) => {
      filterMatchesByStatus(statuses)
      filterMatchesBySource()
      filterMatchesByCountry()
      filterMatchesByShop()
      filterMatchesByAvailability(defaultProductAvailabilityValues)
      filterMatchesByFactor(defaultProductFactorValues)
      filterMatchesByType(allMatchTypeValues)

      startFilterMatchesByRefProdL1Categories()
      startFilterMatchesByRefProdL2Categories()
      startFilterMatchesByRefProdL3Categories()
      startFilterMatchesByRefProdL4Categories()
      filterMatchesByRefProdQuery()
      setSearchTerm("")
      startFilterMatchesByRefProdCountries()
      startFilterMatchesByRefProdFamilies()
    },
    [
      filterMatchesByStatus,
      filterMatchesBySource,
      filterMatchesByCountry,
      filterMatchesByShop,
      filterMatchesByAvailability,
      filterMatchesByFactor,
      filterMatchesByType,
      startFilterMatchesByRefProdCountries,
      startFilterMatchesByRefProdFamilies,
      startFilterMatchesByRefProdL1Categories,
      startFilterMatchesByRefProdL2Categories,
      startFilterMatchesByRefProdL3Categories,
      startFilterMatchesByRefProdL4Categories,
      filterMatchesByRefProdQuery,
    ],
  )

  const handleShortcutAllMatches = useCallback(
    (e) => {
      log.debug("listing all matches. clearing other filters.")
      e.preventDefault()

      doSingleFilter()
    },
    [doSingleFilter],
  )

  const handleShortcutApproved = useCallback(
    (e) => {
      e.preventDefault()
      log.debug("listing all approved matches. clearing other filters.")

      doSingleFilter({
        statuses: [STATUS_APPROVED],
      })
    },
    [doSingleFilter],
  )

  const handleShortcutInReview = useCallback(
    (e) => {
      e.preventDefault()
      log.debug("listing all not approved matches. clearing other filters.")

      doSingleFilter({
        statuses: [STATUS_REVIEW],
      })
    },
    [doSingleFilter],
  )

  const handleClearFilters = useCallback(() => {
    log.debug("clearing all filters.")

    mixpanel.track("Clicked Reset Filters")

    doSingleFilter()
  }, [doSingleFilter])

  const handleOnToggleFilters = useCallback(
    (value) => {
      setMatchesReviewPageSettings({
        showFilters: value,
      })
    },
    [setMatchesReviewPageSettings],
  )

  return (
    <MatchesReviewFilterBarPresenter
      allMatchesShortcutText={allMatchesShortcutText}
      approvedMatchesShortcutText={approvedMatchesShortcutText}
      inReviewMatchesShortcutText={inReviewMatchesShortcutText}
      refProdAutoCompleteSuggestions={autoCompleteSuggestions}
      refProdSearchTerm={searchTerm}
      selectedMatchStatuses={selectedMatchStatuses}
      selectedMatchSources={selectedMatchSources}
      availableMatchCountries={availableMatchCountries}
      selectedMatchCountries={selectedMatchCountries}
      availableMatchShops={availableMatchShops}
      selectedMatchShops={selectedMatchShops}
      selectedMatchProductAvailabilities={selectedMatchProductAvailabilities}
      selectedMatchProductFactors={selectedMatchProductFactors}
      selectedMatchProductTypes={selectedMatchProductTypes}
      matchSourceOptionsList={matchSourceOptionsList}
      availableRefProdCountries={availableRefProdCountries}
      availableRefProdFamilies={availableRefProdFamilies}
      selectedRefProdCountries={selectedRefProdCountries}
      selectedRefProdFamilies={selectedRefProdFamilies}
      showFilters={showFilters}
      // events
      onRefProdCountryFilterChange={handleRefProdCountryFilterChange}
      onRefProdFamilyFilterChange={handleRefProdFamilyFilterChange}
      onRefProdSearchTermChange={handleRefProdSearchTermChange}
      onRefProdSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onRefProdSuggestionsClearRequested={handleSuggestionsClearRequested}
      onRefProdSuggestionSelected={handleSuggestionSelected}
      onMatchSourceFilterChange={handleMatchSourceFilterChange}
      onMatchStatusFilterChange={handleMatchStatusFilterChange}
      onMatchCountryFilterChange={handleMatchCountryFilterChange}
      onMatchShopFilterChange={handleMatchShopFilterChange}
      onMatchProductAvailabilityChange={handleMatchAvailabilityFilterChange}
      onMatchProductFactorChange={handleMatchFactorFilterChange}
      onMatchProductTypeChange={handleMatchProductTypeFilterChange}
      onClearFilters={handleClearFilters}
      onShortcutApproved={handleShortcutApproved}
      onShortcutInReview={handleShortcutInReview}
      onShortcutAllMatches={handleShortcutAllMatches}
      onToggleFilters={handleOnToggleFilters}
    />
  )
}

const mapStateToProps = ({
  refProduct: {
    summary: { total: totalReferenceProductsCount, countries, families } = {},
    autoCompleteSuggestions,
  },
  router: { location },
  matches: {
    list,
    statusSummary,
    selectedMatchSources,
    selectedMatchStatuses,
    availableMatchCountries,
    selectedMatchCountries,
    availableMatchShops,
    selectedMatchShops,
    selectedMatchProductAvailabilities,
    selectedMatchProductFactors,
    selectedMatchProductTypes,
    reviewPageSettings,
    validSources: validMatchSources,
    refProdQuery,
    selectedRefProdCountries,
    selectedRefProdFamilies,
    referenceProductCategories: { categoriesTree, categoryLevels },
    selectedL1Categories,
    selectedL2Categories,
    selectedL3Categories,
    selectedL4Categories,
    l2CategoriesOptions,
    l3CategoriesOptions,
    l4CategoriesOptions,
  },
}) => ({
  totalReferenceProductsCount,
  statusSummary,
  selectedMatchSources,
  validMatchSources,
  selectedMatchStatuses: selectedMatchStatuses.filter((status) => status !== NULL),
  availableMatchCountries,
  selectedMatchCountries,
  availableMatchShops,
  selectedMatchShops,
  selectedMatchProductAvailabilities,
  selectedMatchProductFactors,
  selectedMatchProductTypes,
  selectedRefProdCountries,
  selectedRefProdFamilies,
  location,
  countries,
  families,
  categoryLevels,
  l1CategoryOptions: Object.keys(categoriesTree).map((key) => ({
    name: key,
    count: categoriesTree[key].count,
  })),
  selectedCategories: selectedL1Categories,
  l2CategoriesOptions,
  selectedL2Categories,
  l3CategoriesOptions,
  selectedL3Categories,
  l4CategoriesOptions,
  selectedL4Categories,
  refProdQuery,
  autoCompleteSuggestions,
  reviewPageSettings,
  matchesList: list,
})

const WithErrorBoundary = withErrorBoundary(
  MatchesReviewFilterBar,
  MatchesReviewFilterBarFallbackRenderer,
)

export default connect(mapStateToProps, {
  ...matchActions,
  ...refProdActions,
  doPush: push,
})(WithErrorBoundary)
