import * as Actions from "./action-types"

/* -------------------------------------------------------------------------- */
/*                                    async                                   */
/* -------------------------------------------------------------------------- */

export function enqueueGetCustomerAssortment() {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_ENQUEUE_GET_CUSTOMER_ASSORTMENT,
  }
}

export function enqueueGetCustomerAssortmentFilterOptions() {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_ENQUEUE_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS,
  }
}

export function enqueueGetCustomerAssortmentOutline() {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_ENQUEUE_GET_CUSTOMER_ASSORTMENT_OUTLINE,
  }
}

/* -------------------------------------------------------------------------- */
/*                                  fetchers                                  */
/* -------------------------------------------------------------------------- */

export function getCustomerAssortment() {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT,
  }
}

export function getCustomerAssortmentFilterOptions() {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS,
  }
}

export function getCustomerAssortmentOutline() {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_OUTLINE,
  }
}

export function getCustomerAssortmentExport({ categoryId }) {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_EXPORT,
    categoryId,
  }
}

/* -------------------------------------------------------------------------- */
/*                                  mutations                                 */
/* -------------------------------------------------------------------------- */

export function setAssortmentAnalysisDimensionFilter({ dimension }) {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_SET_DIMENSION_FILTER,
    dimension,
  }
}

export function setAssortmentAnalysisDimensionSort({ direction }) {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_SET_DIMENSION_SORT,
    direction,
  }
}

export function setAssortmentAnalysisFilterOptions({ selectedFilters }) {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_SET_FILTER_OPTIONS,
    selectedFilters,
  }
}

export function setAssortmentAnalysisPage(page) {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_SET_PAGE_SIZE,
    page,
  }
}

export function setAssortmentAnalysisPageSize(pageSize) {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_SET_PAGE,
    pageSize,
  }
}

export function setAssortmentAnalysisProductGroupFilter({ id, all }) {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_SET_PRODUCT_GROUP_FILTER,
    id,
    all,
  }
}

export function setAssortmentAnalysisStepFilter({ step }) {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_SET_STEP_FILTER,
    step,
  }
}

export function setAssortmentAnalysisThresholdFilter({ threshold }) {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_SET_THRESHOLD_FILTER,
    threshold,
  }
}

export function setAssortmentAnalysisMode({ mode }) {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_SET_MODE,
    mode,
  }
}

export function setAssortmentAnalysisHighlight({ highlight }) {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_SET_HIGHLIGHT,
    highlight,
  }
}

export function setAssortmentAnalysisDataFilter({ dataFilter }) {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_SET_DATA_FILTER,
    dataFilter,
  }
}

/* -------------------------------------------------------------------------- */
/*                                  resetters                                 */
/* -------------------------------------------------------------------------- */

export function readAssortmentAnalysisPageStateFromURL({
  defaultFilters,
  defaultSorts,
  defaultFilterOptions,
  defaultMode,
}) {
  return {
    type: Actions.ASSORTMENT_ANALISYS_READ_URL_STATE,
    defaultFilters,
    defaultSorts,
    defaultFilterOptions,
    defaultMode,
  }
}

export function readAssortmentAnalysisPageStateFromPreviousState() {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_REHYDRATE_FROM_PREVIOUS_STATE,
  }
}

/* -------------------------------------------------------------------------- */
/*                                    state                                   */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                  triggers                                  */
/* -------------------------------------------------------------------------- */

export function triggerGetCustomerAssortment() {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_TRIGGER_GET_CUSTOMER_ASSORTMENT,
  }
}

export function triggerGetCustomerAssortmentOutline() {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_TRIGGER_GET_CUSTOMER_ASSORTMENT_OUTLINE,
  }
}

export function triggerGetCustomerAssortmentFilterOptions() {
  return {
    type: Actions.ASSORTMENT_ANALYSIS_TRIGGER_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS,
  }
}
