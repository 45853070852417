import { ReducerFactory, toAsyncInitialSlice } from "../../../util/redux-helpers"
import {
  getUrlPage,
  getUrlPageSize,
  getUrlDimension,
  getUrlFilterOptions,
  getUrlProductGroup,
  getUrlDimensionSort,
} from "./helpers"
import * as Action from "./action-types"
import * as Handler from "./handlers"

import {
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY,
  ASSORTMENT_ANALYSIS_PAGE_META_KEY,
  ASSORTMENT_ANALYSIS_PAGE_PAGINATION_KEY,
  ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY,
  ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_SELECTED_FILTER_OPTIONS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_EXPORT_KEY,
  ASSORTMENT_ANALYSIS_PAGE_HIGHLIGHT_KEY,
  DEFAULT_HIGHLIGHT,
  ASSORTMENT_ANALYSIS_PAGE_DATA_FILTER_KEY,
  DEFAULT_DATA_FILTER,
} from "./constants"

export const initialState = {
  [ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY]: {
    [ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]: getUrlDimension(),
    [ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY]: getUrlProductGroup(),
  },
  [ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY]: getUrlFilterOptions(),
  [ASSORTMENT_ANALYSIS_PAGE_SELECTED_FILTER_OPTIONS_KEY]: getUrlFilterOptions(),
  [ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY]: {
    [ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY]: toAsyncInitialSlice(),
    [ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]: toAsyncInitialSlice(),
  },
  [ASSORTMENT_ANALYSIS_PAGE_META_KEY]: {
    hasArticles: false,
    hasCount: false,
    hasShops: false,
  },
  [ASSORTMENT_ANALYSIS_PAGE_PAGINATION_KEY]: {
    hasNextPage: undefined,
    hasPreviousPage: undefined,
    page: getUrlPage(),
    pageCount: undefined,
    pageSize: getUrlPageSize(),
    resultsCount: undefined,
    dataModeTotal: undefined,
  },
  [ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY]: {
    ...toAsyncInitialSlice(),
  },
  [ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY]: {
    [ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]: getUrlDimensionSort(),
  },
  [ASSORTMENT_ANALYSIS_PAGE_EXPORT_KEY]: {
    loading: false,
  },
  [ASSORTMENT_ANALYSIS_PAGE_HIGHLIGHT_KEY]: DEFAULT_HIGHLIGHT,
  [ASSORTMENT_ANALYSIS_PAGE_DATA_FILTER_KEY]: DEFAULT_DATA_FILTER,
}

const reducer = ReducerFactory.create(initialState, {
  /* -------------------------------------------------------------------------- */
  /*                      comparison overlay event handlers                     */
  /* -------------------------------------------------------------------------- */
  [Action.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT]:
    Handler.handleGetCustomerAssortment,
  [Action.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_ERROR]:
    Handler.handleGetCustomerAssortmentError,
  [Action.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_SUCCESS]:
    Handler.handleGetCustomerAssortmentSuccess,
  [Action.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS]:
    Handler.handleGetCustomerAssortmentFilterOptions,
  [Action.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS_ERROR]:
    Handler.handleGetCustomerAssortmentFilterOptionsError,
  [Action.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS_SUCCESS]:
    Handler.handleGetCustomerAssortmentFilterOptionsSuccess,
  [Action.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_OUTLINE]:
    Handler.handleGetCustomerAssortmentOutline,
  [Action.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_OUTLINE_ERROR]:
    Handler.handleGetCustomerAssortmentOutlineError,
  [Action.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_OUTLINE_SUCCESS]:
    Handler.handleGetCustomerAssortmentOutlineSuccess,
  [Action.ASSORTMENT_ANALISYS_READ_URL_STATE]:
    Handler.handleReadAssortmentAnalysisURLState,
  [Action.ASSORTMENT_ANALYSIS_SET_DIMENSION_FILTER]:
    Handler.handleDimensionFilterChanged,
  [Action.ASSORTMENT_ANALYSIS_SET_DIMENSION_SORT]: Handler.handleDimensionSortChanged,
  [Action.ASSORTMENT_ANALYSIS_SET_FILTER_OPTIONS]: Handler.handleFilterOptionsChanged,
  [Action.ASSORTMENT_ANALYSIS_SET_PRODUCT_GROUP_FILTER]:
    Handler.handleProductGroupFilterChanged,
  [Action.ASSORTMENT_ANALYSIS_SET_STEP_FILTER]: Handler.handleSetStep,
  [Action.ASSORTMENT_ANALYSIS_SET_THRESHOLD_FILTER]: Handler.handleSetThreshold,
  [Action.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_EXPORT]:
    Handler.handleGetCustomerAssortmentExport,
  [Action.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_EXPORT_ERROR]:
    Handler.handleGetCustomerAssortmentExportError,
  [Action.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_EXPORT_SUCCESS]:
    Handler.handleGetCustomerAssortmentExportSuccess,
  [Action.ASSORTMENT_ANALYSIS_SET_MODE]: Handler.handleSetMode,
  [Action.ASSORTMENT_ANALYSIS_SET_HIGHLIGHT]: Handler.handleSetHighlight,
  [Action.ASSORTMENT_ANALYSIS_SET_DATA_FILTER]: Handler.handleSetDataFilter,
})

export default reducer
