import React, { useEffect, useMemo, useState } from "react"
import ReactPlaceholder from "react-placeholder"

import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { useDispatch } from "react-redux"

import { Dialog as Diag, ForbiddenIcon, LoaderSpinner, ToggleGroup } from "../ui"

import * as Action from "../../ducks/widgets/product-comparison/action-creators"

import {
  MatchStatusToggleGroup,
  NoResultsDisplay,
  ProductComparisonTable,
} from "./partials"

import useProductComparisonCompare from "./hooks/use-product-comparison-compare"
import useComparisonDaltixProductStatusFilter from "./hooks/use-product-comparison-status-filter"

import styles from "./ProductComparisonDialog.module.scss"

import {
  AVAILABILITY_ACTIVE,
  AVAILABILITY_INACTIVE,
} from "../../util/product-availability"

const title = "Comparing Products"
const defaultAvailabilities = [AVAILABILITY_ACTIVE]

function ProductComparisonDialog() {
  const dispatch = useDispatch()
  const [availabilities, setInactiveToggle] = useState(defaultAvailabilities)

  const statusFilter = useComparisonDaltixProductStatusFilter()
  const {
    close,
    products: daltixProducts,
    getProducts,
    loading,
    error,
    onlyMatches,
    refProductId: productId,
    isOpen,
  } = useProductComparisonCompare()

  useEffect(() => {
    if (isOpen) {
      getProducts({ status: statusFilter, availabilities })
    }
  }, [availabilities, getProducts, isOpen, statusFilter])

  function handleClose() {
    setInactiveToggle(defaultAvailabilities)
    dispatch(Action.closeProductComparisonWidget())
    close()
  }

  function handleStatusFilterChange(statuses) {
    dispatch(Action.setProductComparisonDaltixProductFilters({ statuses }))
  }

  const { maxWidth, totalProducts } = useMemo(() => {
    const productKeys = Object.keys(daltixProducts)

    return {
      totalProducts: productKeys.length,
      maxWidth:
        productKeys.length > 0
          ? `calc(${productKeys.length + 1} * 356px + 40px)`
          : "100%",
    }
  }, [daltixProducts])

  const isEmpty = totalProducts === 0
  return (
    <Dialog
      PaperProps={{
        sx: {
          minHeight: "calc(100% - 64px)", // remove the overlay margins from the height
          maxWidth,
          transition: "max-width .5s ease-in-out",
        },
      }}
      open={isOpen}
      onClose={handleClose}
      fullWidth
    >
      <DialogContent
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          padding: 0,
        }}
      >
        <Diag.Header>
          <DialogTitle sx={{ padding: "0" }}>{title}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {onlyMatches && (
            <div className={styles.header}>
              {!loading && (
                <h3 className={styles.subtitle}>Matched Products: {totalProducts}</h3>
              )}
              {loading && (
                <ReactPlaceholder
                  style={{ borderRadius: "0.125rem" }}
                  showLoadingAnimation
                  type="rect"
                />
              )}
              <MatchStatusToggleGroup
                selected={statusFilter}
                onChange={handleStatusFilterChange}
              />
              <ToggleGroup.Root
                aria-label="Comparison Match Filters"
                value={availabilities}
                onValueChange={(toggle) => {
                  const avails = toggle.includes(AVAILABILITY_INACTIVE)
                    ? [AVAILABILITY_ACTIVE, AVAILABILITY_INACTIVE]
                    : defaultAvailabilities
                  setInactiveToggle(avails)
                }}
              >
                <ToggleGroup.Item
                  aria-label="Show/hide 'Inactive' products"
                  tooltip="Show/hide 'Inactive' products"
                  value="inactive"
                >
                  <ForbiddenIcon label="inactive" size="xl" />
                </ToggleGroup.Item>
              </ToggleGroup.Root>
            </div>
          )}
        </Diag.Header>
        <Diag.Body mat={false}>
          {loading && <LoaderSpinner />}

          {!loading && !isEmpty && isOpen && (
            <ProductComparisonTable
              productId={productId}
              daltixProducts={daltixProducts}
            />
          )}

          {!loading && isEmpty && isOpen && (
            <NoResultsDisplay src="/assets/images/undraw_empty_re_opql.svg">
              It the appears we have no matches to compare your reference product
              against using the currently active criteria (filters).
            </NoResultsDisplay>
          )}
          {!loading && error && isOpen && (
            <NoResultsDisplay src="/assets/images/undraw_cancel_re_pkdm.svg">
              Oops... Something went wrong. We will investigate.
            </NoResultsDisplay>
          )}
        </Diag.Body>
      </DialogContent>
    </Dialog>
  )
}

export { ProductComparisonDialog }
