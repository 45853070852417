import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import { useAssortmentAnalysisPagination, useSetMode } from "./hooks"
import { DATA_MODE } from "../../ducks/pages/assortment-analysis/constants"

function AssortmentAnalysisTotal() {
  const { t } = useTranslation()
  const { dataModeTotal, resultsCount } = useAssortmentAnalysisPagination()
  const { mode } = useSetMode()

  const total = mode === DATA_MODE ? dataModeTotal : resultsCount

  return (
    <Box alignItems="center" display="flex">
      <Typography variant="caption">
        {t("Products")}: {total || "-"}
      </Typography>
    </Box>
  )
}

export { AssortmentAnalysisTotal }
