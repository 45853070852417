import React from "react"
import { useTranslation } from "react-i18next"
import HtmlTitle from "../html-title/HtmlTitle"

import _styles from "./AssortmentAnalysisPanel.module.scss"
import { AssortmentAnalysisBreadcrumbs } from "./AssortmentAnalysisBreadcrumbs"
import { AssortmentAnalysisCategorySelection } from "./AssortmentAnalysisCategorySelection"

function AssortmentAnalysisCategoryPanel() {
  const { t } = useTranslation()

  return (
    <>
      <HtmlTitle>{t("Assortment Analysis Categories")}</HtmlTitle>
      <AssortmentAnalysisBreadcrumbs />
      <AssortmentAnalysisCategorySelection />
    </>
  )
}

AssortmentAnalysisCategoryPanel.propTypes = {}

export default AssortmentAnalysisCategoryPanel
