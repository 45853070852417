import { get } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import useLocalStorage from "../../../hooks/use-local-storage"
import * as Actions from "../../../ducks/pages/assortment-analysis/action-creators"
import {
  ASSORTMENT_ANALYSIS_PAGE_HIGHLIGHT_KEY,
  ASSORTMENT_ANALYSIS_PAGE_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

/**
 * @returns {{ highlight: string | undefined, setHighlight: ({ highlight }) => void}}
 *  the currently selected step and a setter function
 */
export function useSetHighlight() {
  const dispatch = useDispatch()
  const [highlightSettings, setHighlightSettings] = useLocalStorage(
    "assortment-analysis-settings-highlight",
    false,
  )

  const highlight = useSelector((state) =>
    get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_HIGHLIGHT_KEY,
    ]),
  )

  function setHighlight({ highlight: newHighlight }) {
    setHighlightSettings(newHighlight)
    dispatch(Actions.setAssortmentAnalysisHighlight({ highlight: newHighlight }))
  }

  return { highlight, savedHighlight: highlightSettings, setHighlight }
}
