import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import ReactCountryFlag from "react-country-flag"
import { Box, Typography } from "@mui/material"
import useShop from "../../../../hooks/use-shop"

import styles from "./AssortmentAnalysisColumnHeaderShop.module.scss"
import { GRID_MODE } from "../../../../ducks/pages/assortment-analysis/constants"

function AssortmentAnalysisColumnHeaderShop({ country, id, mode }) {
  const { t } = useTranslation()
  const { logo, name } = useShop(id)
  const isGridMode = mode === GRID_MODE

  const title = (
    <Box alignItems="center" display="flex" sx={{ pl: 1 }}>
      <Typography className={styles.name} sx={{ pr: 1 }}>
        {t(name)}
      </Typography>
      <ReactCountryFlag className={styles.flag} countryCode={country} />
    </Box>
  )

  const renderForGridMode = () => (
    <Box display="flex" alignItems="center">
      <img className={styles.logo} src={logo} alt={`${name} logo`} />
      {title}
    </Box>
  )

  const renderForDataMode = () =>
    logo ? <img className={styles.logo} src={logo} alt={`${name} logo`} /> : title

  return (
    <div
      className={clsx(
        styles.container,
        isGridMode && styles.left,
        !isGridMode && styles.right,
      )}
    >
      {isGridMode && renderForGridMode()}
      {!isGridMode && renderForDataMode()}
    </div>
  )
}

AssortmentAnalysisColumnHeaderShop.propTypes = {
  country: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  mode: PropTypes.string,
}
AssortmentAnalysisColumnHeaderShop.defaultProps = {
  mode: GRID_MODE,
}

export { AssortmentAnalysisColumnHeaderShop }
