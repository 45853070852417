import queryString from "query-string"
import {
  QP_PAGE,
  QP_PAGE_SIZE,
  QP_DIMENSION,
  QP_PRODUCT_GROUP,
  numberQuery,
  stringQuery,
  arrayQuery,
  QP_SORT,
  QP_STEP,
  QP_MODE,
  QP_ALL,
  QP_THRESHOLD,
} from "../../../../util/query-param"
import {
  SORT_ASC,
  SORT_DESC,
  createSortProperty,
  extractSortPropertyValue,
} from "../../../../util/sorting"
import { DEFAULT_THRESHOLD, DIMENSION_PRICE, GRID_MODE, STEP_PRICE } from "../constants"

/* -------------------------------------------------------------------------- */
/*                                  accessors                                 */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                array queries                               */
/* -------------------------------------------------------------------------- */

function getUrlDimensionSort() {
  const ascPattern = createSortProperty("dimension", SORT_ASC)
  const descPattern = createSortProperty("dimension", SORT_DESC)

  const value = arrayQuery(QP_SORT).find((s) => [ascPattern, descPattern].includes(s))

  if (value) {
    const direction = extractSortPropertyValue(value)

    return direction
  }

  return SORT_ASC
}

function getUrlFilterOptions() {
  const params = queryString.parse(window.location.search)

  delete params[QP_SORT]
  delete params[QP_DIMENSION]
  delete params[QP_PRODUCT_GROUP]
  delete params[QP_STEP]
  delete params[QP_MODE]
  delete params[QP_ALL]
  delete params[QP_THRESHOLD]

  Object.keys(params).forEach((k) => {
    const val = params[k]
    if (!Array.isArray(val)) {
      params[k] = [val]
    }
  })

  return params
}

/* -------------------------------------------------------------------------- */
/*                               string queries                               */
/* -------------------------------------------------------------------------- */

function getUrlDimension() {
  return stringQuery(QP_DIMENSION, "price")
}

function getUrlProductGroup() {
  return numberQuery(QP_PRODUCT_GROUP, 0)
}

/* -------------------------------------------------------------------------- */
/*                               number queries                               */
/* -------------------------------------------------------------------------- */

function getUrlPage() {
  return numberQuery(QP_PAGE, 1)
}

function getUrlPageSize() {
  return numberQuery(QP_PAGE_SIZE, 25)
}

function getUrlStep() {
  return numberQuery(QP_STEP, STEP_PRICE, true)
}

function getUrlThreshold() {
  return numberQuery(QP_THRESHOLD, DEFAULT_THRESHOLD, true)
}

function getAllProducts() {
  return stringQuery(QP_ALL, "false")
}

function getUrlMode() {
  return stringQuery(QP_MODE, GRID_MODE)
}

/* -------------------------------------------------------------------------- */
/*                                miscellaneous                               */
/* -------------------------------------------------------------------------- */

function assortmentAnalysisURLState(
  defaultFilters = {},
  defaultSorts = {},
  defaultFilterOptions = {},
  defaultMode = GRID_MODE,
) {
  return {
    filters: {
      page: getUrlPage(defaultFilters.page || 1),
      pageSize: getUrlPageSize(defaultFilters.pageSize || 25),
      dimension: getUrlDimension(defaultFilters.dimension || DIMENSION_PRICE),
      productGroup: getUrlProductGroup(),
      step: getUrlStep(defaultFilters.step),
      all: getAllProducts(defaultFilters.all),
      threshold: getUrlThreshold(defaultFilters.threshold || DEFAULT_THRESHOLD),
    },
    sorts: {
      dimension: getUrlDimensionSort(defaultSorts.dimension || SORT_ASC),
    },
    selectedFilters: getUrlFilterOptions(defaultFilterOptions),
    mode: getUrlMode(defaultMode),
  }
}

export {
  assortmentAnalysisURLState,
  getUrlPage,
  getUrlPageSize,
  getUrlDimension,
  getUrlDimensionSort,
  getUrlFilterOptions,
  getUrlProductGroup,
  getUrlStep,
  getUrlThreshold,
  getAllProducts,
  getUrlMode,
}
