import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, Typography } from "@mui/material"

import styles from "./AssortmentAnalysisRowHeader.module.scss"

import {
  DATA_MODE,
  DIMENSION_BRAND_TYPE,
  DIMENSION_PRICE,
  GRID_MODE,
} from "../../../../ducks/pages/assortment-analysis/constants"
import { mapBrandLayerName } from "../../../../util/brands"
import { EUR_SYMBOL } from "../../../../util/constants"
import { DimensionBrandPropType, DimensionPricePropType } from "../../prop-types"
import { useProductGroupFilter } from "../../hooks"

function PriceDimensionRowHeader({ dimension }) {
  const { start, end } = dimension
  const text =
    start === undefined || end === undefined
      ? ""
      : `${toFriendly(start)} - ${toFriendly(end)}`

  function toFriendly(val) {
    const maybeNumber = Number(val)

    return `${EUR_SYMBOL}${Number.isNaN(maybeNumber) ? val : maybeNumber.toFixed(2)}`
  }

  return (
    <Typography variant="body2" className={styles.heading}>
      {text}
    </Typography>
  )
}

PriceDimensionRowHeader.propTypes = {
  dimension: DimensionPricePropType.isRequired,
}

function BrandDimensionRowHeader({ dimension }) {
  const { t } = useTranslation()
  const { id } = dimension

  return (
    <Typography variant="body2" className={styles.heading}>
      {t(mapBrandLayerName(id))}
    </Typography>
  )
}

BrandDimensionRowHeader.propTypes = {
  dimension: DimensionBrandPropType.isRequired,
}

export function AssortmentAnalysisRowHeader({ dimension, mode }) {
  const { t } = useTranslation()
  const [_, setProductGroupId] = useProductGroupFilter()
  let Child = null

  if (mode === DATA_MODE) {
    const handleGroupClick = () => {
      setProductGroupId({ id: dimension.groupId })
    }

    const text = (
      <Typography variant="body2" fontWeight={dimension.isBold ? 700 : 400}>
        {t(dimension.groupName)}
      </Typography>
    )

    const noGroup = dimension.groupId === -1 || dimension.groupId === 0
    if (noGroup) {
      Child = text
    } else {
      Child = (
        <Button
          color="primary"
          variant="text"
          onClick={handleGroupClick}
          sx={{ justifyContent: "start" }}
        >
          {text}
        </Button>
      )
    }

    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="start"
        p={2}
        pl={noGroup ? 2 : 0}
        width="100%"
      >
        {Child}
      </Box>
    )
  }

  switch (dimension.type) {
    case DIMENSION_BRAND_TYPE: {
      Child = <BrandDimensionRowHeader dimension={dimension} />
      break
    }
    case DIMENSION_PRICE: {
      Child = <PriceDimensionRowHeader dimension={dimension} />
      break
    }
    default: {
      throw new Error(
        `AssortmentAnalysisRowHeader received unknown dimension type: '${JSON.stringify(dimension)}'`,
      )
    }
  }

  return <div className={styles.container}>{Child}</div>
}

AssortmentAnalysisRowHeader.propTypes = {
  dimension: PropTypes.oneOfType([DimensionBrandPropType, DimensionPricePropType])
    .isRequired,
  mode: PropTypes.oneOf([DATA_MODE, GRID_MODE]).isRequired,
}
