export const ASSORTMENT_ANALYSIS_PAGE_KEY = "AssortmentAnalysisPage"

export const ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY = "filters"
export const ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY = "filterOptions"
export const ASSORTMENT_ANALYSIS_PAGE_SELECTED_FILTER_OPTIONS_KEY = "selectedFilters"
export const ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY = "filtersOutline"

export const ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY = "dimension"
export const ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY = "productGroup"

export const ASSORTMENT_ANALYSIS_PAGE_META_KEY = "meta"
export const ASSORTMENT_ANALYSIS_PAGE_PAGINATION_KEY = "pagination"
export const ASSORTMENT_ANALYSIS_PAGE_REQUEST_KEY = "request"
export const ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY = "sorts"

export const ASSORTMENT_ANALYSIS_PAGE_EXPORT_KEY = "exportData"

export const DIMENSION_PRICE = "price"
export const DIMENSION_BRAND_TYPE = "brand-type"
export const DIMENSION_PRODUCT = "prod"
export const STEP_PRICE = "2.5"
export const DEFAULT_THRESHOLD = "30"
export const DEFAULT_HIGHLIGHT = false
export const DEFAULT_DATA_FILTER = Object.freeze({
  value: "number",
  format: "default",
})

export const DATA_MODE = "data"
export const GRID_MODE = "grid"

export const ASSORTMENT_ANALYSIS_PAGE_ALL_KEY = "all"
export const ASSORTMENT_ANALYSIS_PAGE_MODE_KEY = "mode"
export const ASSORTMENT_ANALYSIS_PAGE_STEP_KEY = "step"
export const ASSORTMENT_ANALYSIS_PAGE_THRESHOLD_KEY = "threshold"
export const ASSORTMENT_ANALYSIS_PAGE_HIGHLIGHT_KEY = "highlight"
export const ASSORTMENT_ANALYSIS_PAGE_DATA_FILTER_KEY = "dataFilter"

export const SUPPORTED_DIMENSIONS = Object.freeze([
  DIMENSION_PRICE,
  DIMENSION_BRAND_TYPE,
  DIMENSION_PRODUCT,
])

export const DataFilterValueOptions = Object.freeze([
  {
    id: "number",
    displayValue: "Number",
  },
  {
    id: "percentage",
    displayValue: "Percentage",
  },
])
export const DataFilterFormatOptions = Object.freeze([
  {
    id: "default",
    displayValue: "Default",
  },
  {
    id: "colour",
    displayValue: "Colour range (single)",
  },
  {
    id: "colourduo",
    displayValue: "Colour range (duo)",
  },
])
