import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { Box } from "@mui/material"
import { useProductGroupsOutline } from "./hooks"
import { NoResultsDisplay } from "./table/partials"
import { LoaderSpinner } from "../ui"
import { assortmentToolRoutes } from "../../routes/assortment-tool/AssortmentTool"

function AssortmentAnalysisContainer({ children }) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { data: productGroups = [], isFetching, isError } = useProductGroupsOutline()

  let categoryId = 0
  if (pathname.startsWith(assortmentToolRoutes.analysis)) {
    categoryId = Number(pathname.split("/").pop())
  }

  const props = {
    alignItems: "center",
    display: "flex",
    height: "100vh",
    justifyContent: "center",
  }
  const renderError = (text) => (
    <Box {...props}>
      <NoResultsDisplay src="/assets/images/undraw_empty_re_opql.svg">
        {text}
      </NoResultsDisplay>
    </Box>
  )

  if (isFetching) {
    return (
      <Box {...props}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <LoaderSpinner />
        </Box>
      </Box>
    )
  }

  if (isError) {
    return renderError(
      t(
        `There was an error trying to access the selected category.
      Please try again later or select another category.
      Contact us if the problem persists.`,
      ),
    )
  }

  const productGroup = productGroups.find((group) => group.categoryId === categoryId)
  if (!productGroup) {
    return renderError(
      t(
        `We couldn't find the selected category.
        Please select another category.`,
      ),
    )
  }

  return <>{children}</>
}

AssortmentAnalysisContainer.propTypes = {
  children: PropTypes.node,
}
AssortmentAnalysisContainer.defaultProps = {
  children: null,
}

export { AssortmentAnalysisContainer }
