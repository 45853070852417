import React, { Suspense, memo } from "react"
import PropTypes from "prop-types"
import { Switch } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"
import { ContentContainer, SideNavigation } from "../../components/side-navigation"
import HtmlTitle from "../../components/html-title/HtmlTitle"
import AssortmentAnalysisCategoryPanel from "../../components/assortment-analysis/AssortmentAnalysisCategoryPanel"
import RestrictedRoute from "../../hocs/restricted-route"

function AssortmentToolCategoryPresenter({ routes }) {
  const { t } = useTranslation()

  return (
    <Box sx={{ display: "flex" }}>
      <SideNavigation />
      <ContentContainer sx={{ backgroundColor: "white" }}>
        <HtmlTitle>{t("Assortment Analysis Categories")}</HtmlTitle>
        <Suspense>
          <Switch>
            <RestrictedRoute
              path={routes.categorySelector}
              component={AssortmentAnalysisCategoryPanel}
            />
          </Switch>
        </Suspense>
      </ContentContainer>
    </Box>
  )
}

AssortmentToolCategoryPresenter.propTypes = {
  routes: PropTypes.shape({
    categorySelector: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(AssortmentToolCategoryPresenter)
