import {
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_THRESHOLD_KEY,
} from "../constants"

export function handleSetThreshold(state, payload) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY]: {
      ...state[ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY],
      [ASSORTMENT_ANALYSIS_PAGE_THRESHOLD_KEY]: payload.threshold,
    },
  }
}
