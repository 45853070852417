import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import mixpanel from "mixpanel-browser"
import { Box, MenuItem, Select, Typography } from "@mui/material"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import { useDataFilters } from "./hooks"
import { buildAnalyticsEventName } from "../../util/user-monitoring"
import {
  DataFilterFormatOptions,
  DataFilterValueOptions,
  DEFAULT_DATA_FILTER,
} from "../../ducks/pages/assortment-analysis/constants"

const analyticsEventNameValue = buildAnalyticsEventName({
  feature: "Assortment Analysis",
  target: "Value",
  userAction: "Selected",
})
const analyticsEventNameFormat = buildAnalyticsEventName({
  feature: "Assortment Analysis",
  target: "Format",
  userAction: "Selected",
})

const NumericSelect = ({ dataTestId, label, onChange, options = [], value: val }) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="caption">{t(label)}:</Typography>
      <Select
        data-testid={dataTestId}
        color="primary"
        IconComponent={ExpandMoreRoundedIcon}
        inputProps={{
          sx: { py: 1 / 2 },
        }}
        onChange={onChange}
        size="small"
        variant="outlined"
        sx={{
          minWidth: 60,
          ml: 1 / 2,
          mr: 2,
        }}
        value={val}
      >
        {options.map((option) => (
          <MenuItem color="primary" key={option.id} value={option.id}>
            {t(option.displayValue)}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
NumericSelect.propTypes = {
  dataTestId: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  value: PropTypes.string.isRequired,
}
NumericSelect.defaultProps = {
  dataTestId: "",
  options: [],
}

export function AssortmentAnalysisNumeric() {
  const { dataFilter, setDataFilter } = useDataFilters()
  const [value, setValue] = useState(dataFilter?.value || DEFAULT_DATA_FILTER.value)
  const [format, setFormat] = useState(dataFilter?.format || DEFAULT_DATA_FILTER.format)

  const handleValueSelection = ({ target }) => {
    setValue(target.value)
    setDataFilter({ dataFilter: { ...dataFilter, value: target.value } })
    mixpanel.track(analyticsEventNameValue, { option: target.value })
  }
  const handleFormatSelection = ({ target }) => {
    setFormat(target.value)
    setDataFilter({ dataFilter: { ...dataFilter, format: target.value } })
    mixpanel.track(analyticsEventNameFormat, { option: target.value })
  }

  return (
    <Box display="inline-flex" alignItems="center">
      <NumericSelect
        dataTestId="assortment-analysis-numeric"
        label="Value"
        onChange={handleValueSelection}
        options={DataFilterValueOptions}
        value={value}
      />
      <NumericSelect
        dataTestId="assortment-analysis-format"
        label="Format"
        onChange={handleFormatSelection}
        options={DataFilterFormatOptions}
        value={format}
      />
    </Box>
  )
}

AssortmentAnalysisNumeric.propTypes = {}

AssortmentAnalysisNumeric.defaultProps = {}
