import PropTypes from "prop-types"
import React, { useState } from "react"
import * as Slider from "@radix-ui/react-slider"

import styles from "./RangeSlider.module.scss"

function RangeSliderRoot(props) {
  const { defaultValue, onValueChange, min, max, prefix, suffix, ...rest } = props

  const [thumbsValue, setThumbsValue] = useState(defaultValue || [0, 100])

  return (
    <Slider.Root
      {...rest}
      className={styles["slider-root"]}
      defaultValue={defaultValue}
      max={max}
      min={min}
      onValueChange={(val) => {
        setThumbsValue(val)
        onValueChange(val)
      }}
    >
      <Slider.Track className={styles["slider-track"]}>
        <Slider.Range className={styles["slider-range"]} />
      </Slider.Track>
      <Slider.Thumb
        className={styles["slider-thumb"]}
        data-tooltip={`${prefix}${thumbsValue[0]}${suffix}`}
      />
      <Slider.Thumb
        className={styles["slider-thumb"]}
        data-tooltip={`${prefix}${thumbsValue[1]}${suffix}`}
      />
    </Slider.Root>
  )
}

RangeSliderRoot.propTypes = {
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  max: PropTypes.number,
  min: PropTypes.number,
  onValueChange: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
}

RangeSliderRoot.defaultProps = {
  defaultValue: [0, 100],
  max: 100,
  min: 0,
  onValueChange: () => undefined,
  prefix: "",
  suffix: "",
}

export const RangeSlider = {
  Root: RangeSliderRoot,
}
