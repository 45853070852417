import { get } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import useLocalStorage from "../../../hooks/use-local-storage"
import * as Actions from "../../../ducks/pages/assortment-analysis/action-creators"
import {
  ASSORTMENT_ANALYSIS_PAGE_STEP_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

/**
 * @returns {{ step: string | undefined, setStep: ({ step }) => void}}
 *  the currently selected step and a setter function
 */
export function useStepFilter() {
  const dispatch = useDispatch()
  const [stepSettings, setStepSettings] = useLocalStorage(
    "assortment-analysis-settings-step",
    2.5,
  )

  const step = useSelector((state) =>
    get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
      ASSORTMENT_ANALYSIS_PAGE_STEP_KEY,
    ]),
  )

  function setStep({ step: newStep }) {
    setStepSettings(newStep)
    dispatch(Actions.setAssortmentAnalysisStepFilter({ step: newStep }))
  }

  return { step, savedStep: stepSettings, setStep }
}
