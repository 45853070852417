import React from "react"
import PropTypes from "prop-types"
import { Typography } from "@mui/material"
import Image from "../../../img/Image"

import styles from "./NoResultsDisplay.module.scss"

export function NoResultsDisplay({ children, alt, src }) {
  return (
    <div role="alert" className={styles.container}>
      <Image alt={alt} src={src} className={styles.image} />
      <Typography component="h3" className={styles.message}>
        {children}
      </Typography>
    </div>
  )
}

NoResultsDisplay.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
}

NoResultsDisplay.defaultProps = {
  alt: "",
}
