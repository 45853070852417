import React from "react"
import mixpanel from "mixpanel-browser"
import PropTypes from "prop-types"
import ReactPlaceholder from "react-placeholder"
import IosShareIcon from "@mui/icons-material/IosShare"
import LoadingButton from "@mui/lab/LoadingButton"
import { useTranslation } from "react-i18next"
import {
  useProductGroupExport,
  useProductGroupsOutline,
  useProductGroupFilter,
} from "./hooks"

const ButtonPalceHolder = (
  <ReactPlaceholder
    className="rounded"
    showLoadingAnimation
    style={{ width: 128, height: 32, margin: "0" }}
    type="rect"
  />
)

function AssortmentAnalysisExportCategoryButton({
  category,
  label,
  variant,
  ...props
}) {
  const { t } = useTranslation()
  const { categoryExport, exportCategory } = useProductGroupExport()
  const { data: productGroups, isIdle, isFetching, isError } = useProductGroupsOutline()
  const [productGroupId] = useProductGroupFilter()

  if (isIdle || isFetching) {
    return ButtonPalceHolder
  }

  if (isError) {
    return null
  }

  if (!productGroups || (productGroups && productGroups.length === 0)) {
    return (
      <LoadingButton
        {...props}
        size="small"
        color="primary"
        variant={variant}
        startIcon={<IosShareIcon fontSize="20" />}
        disabled
      >
        {label || t("Export Category")}
      </LoadingButton>
    )
  }

  const productGroup =
    productGroups.find((opt) => opt.id === productGroupId) || productGroups[0]

  const { categoryId, categoryName } = productGroup

  async function handleExportClick(e) {
    e.preventDefault()
    exportCategory(category || categoryId)
    mixpanel.track("A3Tool - Export Category", { category: category || categoryId })
    e.stopPropagation()
  }

  return (
    <LoadingButton
      {...props}
      size="small"
      onClick={handleExportClick}
      color="primary"
      variant={variant}
      loading={categoryExport.loading}
      loadingPosition="start"
      startIcon={<IosShareIcon fontSize="20" />}
      data-testid="assortment-export-category"
    >
      {label || t(`Export "${categoryName}"`)}
    </LoadingButton>
  )
}

AssortmentAnalysisExportCategoryButton.propTypes = {
  category: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
}

AssortmentAnalysisExportCategoryButton.defaultProps = {
  category: null,
  label: null,
  variant: "outlined",
}

export { AssortmentAnalysisExportCategoryButton }
