import {
  ASSORTMENT_ANALYSIS_PAGE_ALL_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY,
} from "../constants"

export function handleProductGroupFilterChanged(state, payload) {
  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY]: {
      ...state[ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY],
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY]: payload.id,
      [ASSORTMENT_ANALYSIS_PAGE_ALL_KEY]: payload.all,
    },
  }
}
