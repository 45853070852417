import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { replace } from "connected-react-router"
import AssortmentToolCategoryPresenter from "./AssortmentToolCategoryPresenter"
import { featureNamesMap } from "../../integrations/split"

const assortmentToolRoutes = Object.freeze({
  base: "/assortment",
  categorySelector: "/assortment/analysis",
})

const assortmentFeatureFlags = [featureNamesMap.ASSORTMENT_ANALYSIS_CUSTOMER]

function isBaseRoute(pathname) {
  return [assortmentToolRoutes.base, `${assortmentToolRoutes.base}/`].includes(pathname)
}

function AssortmentToolHome({ location, ...rest }) {
  const dispatch = useDispatch()
  const { pathname } = location

  useEffect(() => {
    if (isBaseRoute(pathname)) {
      dispatch(replace(assortmentToolRoutes.categorySelector))
    }
  }, [dispatch, pathname])

  return <AssortmentToolCategoryPresenter routes={assortmentToolRoutes} {...rest} />
}

AssortmentToolHome.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default AssortmentToolHome
export { assortmentToolRoutes, assortmentFeatureFlags }
