/* eslint-disable import/prefer-default-export */

import { slugify } from "./strings"

export const sameBrand = (brand1, brand2) => {
  if (brand1 && brand2) {
    return slugify(brand1) === slugify(brand2)
  }
  return false
}

export const brandLayerNameMap = {
  NB: "National Brand",
  PL1: "First Price",
  PL2: "Private Label",
}

export const mapBrandLayerName = (layer) => {
  if (!layer) {
    return ""
  }
  return brandLayerNameMap[`${layer}`.toUpperCase()] || layer
}
