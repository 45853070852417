import React from "react"
import { useTranslation } from "react-i18next"
import { Box, useTheme } from "@mui/material"
import HtmlTitle from "../html-title/HtmlTitle"

import _styles from "./AssortmentAnalysisPanel.module.scss"
import AssortmentAnalysisToolbar from "./AssortmentAnalysisToolbar"
import { AssortmentAnalysisBreadcrumbs } from "./AssortmentAnalysisBreadcrumbs"
import { AssortmentAnalysisContainer } from "./AssortmentAnalysisContainer"
import { AssortmentAnalysisTable } from "./table"

function AssortmentAnalysisPanel() {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box bgcolor={theme.palette.background.default}>
      <HtmlTitle>{t("Assortment Analysis")}</HtmlTitle>
      <AssortmentAnalysisContainer>
        <AssortmentAnalysisBreadcrumbs />
        <AssortmentAnalysisToolbar />
        <Box px={2} height="calc(100vh - 170px)">
          <AssortmentAnalysisTable />
        </Box>
      </AssortmentAnalysisContainer>
    </Box>
  )
}

AssortmentAnalysisPanel.propTypes = {}

export default AssortmentAnalysisPanel
