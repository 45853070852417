import { get } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import * as Actions from "../../../ducks/pages/assortment-analysis/action-creators"
import {
  ASSORTMENT_ANALYSIS_PAGE_MODE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

/**
 * @returns {{ mode: string | undefined, setMode: ({ mode }) => void}}
 *  the currently selected step and a setter function
 */
export function useSetMode() {
  const dispatch = useDispatch()

  const mode = useSelector((state) =>
    get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_MODE_KEY,
    ]),
  )

  function setMode({ mode: newMode }) {
    dispatch(Actions.setAssortmentAnalysisMode({ mode: newMode }))
  }

  return { mode, setMode }
}
