import {
  ASSORTMENT_ANALYSIS_PAGE_ALL_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_MODE_KEY,
  GRID_MODE,
} from "../constants"

export function handleSetMode(state, payload) {
  // reset the all select option if the mode is grid
  let all = {}
  if (payload.mode === GRID_MODE) {
    all = {
      [ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY]: {
        ...state[ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY],
        [ASSORTMENT_ANALYSIS_PAGE_ALL_KEY]: false,
      },
    }
  }

  return {
    ...state,
    [ASSORTMENT_ANALYSIS_PAGE_MODE_KEY]: payload.mode,
    ...all,
  }
}
