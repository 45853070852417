import { useState, useEffect } from "react"

function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => {
    let currentValue

    if (typeof key === "string") {
      try {
        currentValue = JSON.parse(localStorage.getItem(key) || String(defaultValue))
      } catch (error) {
        currentValue = defaultValue
      }
    }

    return currentValue
  })

  useEffect(() => {
    if (typeof key === "string") {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }, [value, key])

  return [value, setValue]
}

export default useLocalStorage
