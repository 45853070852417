import queryString from "query-string"
import { ofType } from "redux-observable"
import { debounceTime, filter, map, mapTo, tap } from "rxjs/operators"

import { get } from "lodash"
import { LOCATION_CHANGE, replace } from "connected-react-router"
import * as APIService from "../../../api/service"
import { isInPage, isUserAuthenticated, withParent } from "../../../util/epics"
import * as Actions from "./action-types"

import { buildEpic } from "../../../util/redux-observable-helpers"
import {
  enqueueGetReferenceProductsMatchesByShop,
  getReferenceProductsMatchesByShop,
  readMatchesOverviewPageStateFromPreviousState,
  readMatchesOverviewPageStateFromURL,
  setMatchesOverviewPage,
} from "./action-creators"

import { setSearchParams } from "../../../util/redux-helpers"
import {
  QP_CATEGORIES,
  QP_COUNTRY,
  QP_FAMILY,
  QP_MATCH_COUNT,
  QP_PAGE,
  QP_PAGE_SIZE,
  QP_QUERY,
  QP_QUERY_FACET,
  QP_SHOP,
  QP_STATUS,
} from "../../../util/query-param"
import { arrayWithElementsOrUndefined } from "../../../util/array"
import {
  MATCHES_OVERVIEW_PAGE_FILTERS_KEY,
  MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
  MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
} from "./constants"
import { getValuesOfSearchParams } from "./helpers"
import { matchingEngineRoutes } from "../../../routes/matching-engine/MatchingEngine"
import { STATUS_APPROVED, STATUS_FLAGGED } from "../../../util/match-status"

const DEBOUNCE_MILLISECONDS = 250

const one = (value) => value || undefined
const many = arrayWithElementsOrUndefined

function isVisitingMatchesOverview(state$) {
  return (
    isUserAuthenticated(state$.value) &&
    isInPage(matchingEngineRoutes.overview, state$.value)
  )
}

function parametrizeState(stateSnapshot) {
  const { page, pageSize } = get(stateSnapshot, [
    "pages",
    "MatchesOverviewPage",
    "pagination",
  ])

  const matchFilters = get(stateSnapshot, [
    "pages",
    "MatchesOverviewPage",
    MATCHES_OVERVIEW_PAGE_FILTERS_KEY,
    MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
  ])

  const referenceFilters = get(stateSnapshot, [
    "pages",
    "MatchesOverviewPage",
    MATCHES_OVERVIEW_PAGE_FILTERS_KEY,
    MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
  ])

  const parameters = {
    [QP_CATEGORIES]: many(getValuesOfSearchParams(referenceFilters.categories)),
    [QP_COUNTRY]: many(getValuesOfSearchParams(matchFilters.countries)),
    [QP_FAMILY]: many(getValuesOfSearchParams(referenceFilters.families)),
    [QP_MATCH_COUNT]: matchFilters.count,
    [QP_PAGE]: page,
    [QP_PAGE_SIZE]: pageSize,
    [QP_QUERY]: one(referenceFilters.searchTerm),
    [QP_QUERY_FACET]: one(referenceFilters.searchFacet),
    [QP_SHOP]: many(getValuesOfSearchParams(matchFilters.shops)),
    [QP_STATUS]: many(matchFilters.status),
  }

  return parameters
}

/* -------------------------------------------------------------------------- */
/*                     url bi-directional synchronisation                     */
/* -------------------------------------------------------------------------- */

function triggerMatchesOverviewStoreUpdateFromHistoryEvent(action$, state$) {
  return action$.pipe(
    ofType(LOCATION_CHANGE),
    filter(() => isVisitingMatchesOverview(state$)),
    map((action) => {
      const currentLocation = state$.value.router.location
      const previousPath = state$.value.app.prevLocation.pathname

      const { match, review, overview } = matchingEngineRoutes
      const canRehydrate = [match, review].some((x) => previousPath?.startsWith(x))

      if (canRehydrate) {
        return readMatchesOverviewPageStateFromPreviousState()
      }

      const comesFromDifferentPage = previousPath && !previousPath.startsWith(overview)
      const comesFromURL = action.payload.isFirstRendering
      const canApplyDefaults =
        (comesFromURL || comesFromDifferentPage) && currentLocation.search === ""

      const defaultValues = canApplyDefaults
        ? {
            matchStatus: [STATUS_APPROVED],
            page: 1,
            pageSize: 25,
          }
        : {}

      return readMatchesOverviewPageStateFromURL({
        defaultValues,
      })
    }),
  )
}

function triggerMatchesOverviewSearchParamsUpdateFromStateRehydration(action$, state$) {
  return action$.pipe(
    ofType(Actions.MATCHES_OVERVIEW_REHYDRATE_FROM_PREVIOUS_STATE),
    filter(() => isVisitingMatchesOverview(state$)),
    map(() =>
      replace({
        pathname: state$.value.router.location.pathname,
        search: queryString.stringify(parametrizeState(state$.value)),
      }),
    ),
  )
}

function triggerResetPageEpic(action$, state$) {
  return action$.pipe(
    ofType(
      /* -------------------------------------------------------------------------- */
      /*                            clear filter actions                            */
      /* -------------------------------------------------------------------------- */
      Actions.CLEAR_MATCHES_OVERVIEW_APPLIED_FILTERS,
      Actions.CLEAR_MATCHES_OVERVIEW_REF_PRODUCTS_SEARCH,
      /* -------------------------------------------------------------------------- */
      /*                                quick filters                               */
      /* -------------------------------------------------------------------------- */
      Actions.SET_MATCHES_OVERVIEW_MATCH_STATUSES_FILTER,
      Actions.SET_MATCHES_OVERVIEW_REF_PRODUCTS_SEARCH,
      Actions.TOGGLE_FILTER_APPROVED_MATCHES,
      Actions.TOGGLE_FILTER_DISCARDED_MATCHES,
      Actions.TOGGLE_FILTER_IN_REVIEW_MATCHES,
      Actions.TOGGLE_FILTER_REF_PRODUCTS_WITHOUT_MATCHES,
      /* -------------------------------------------------------------------------- */
      /*                                slow filters                                */
      /* -------------------------------------------------------------------------- */
      Actions.TRIGGER_APPLY_MATCHES_OVERVIEW_FILTERS,
    ),
    filter(() => isVisitingMatchesOverview(state$)),
    mapTo(setMatchesOverviewPage(1)),
  )
}

/* -------------------------------------------------------------------------- */
/*                            matches overview data                           */
/* -------------------------------------------------------------------------- */

function triggerGetReferenceProductsMatchesByShopFromHistoryEventEpic(action$, state$) {
  return action$.pipe(
    ofType(Actions.READ_MATCHES_OVERVIEW_URL_STATE),
    filter(() => isVisitingMatchesOverview(state$)),
    map((action) => withParent(action, enqueueGetReferenceProductsMatchesByShop())),
  )
}

function triggerGetReferenceProductsMatchesByShopFromUserInteractionEpic(
  action$,
  state$,
) {
  return action$.pipe(
    ofType(
      Actions.SET_MATCHES_OVERVIEW_PAGE,
      Actions.SET_MATCHES_OVERVIEW_PAGE_SIZE,
      Actions.TRIGGER_GET_REF_PRODUCTS_MATCHES_BY_SHOP,
    ),
    filter(() => isVisitingMatchesOverview(state$)),
    tap(() => setSearchParams(parametrizeState(state$.value))),
    map((action) => withParent(action, enqueueGetReferenceProductsMatchesByShop())),
  )
}

function enqueueGetReferenceProductMatchesByShopEpic(action$, _state$) {
  return action$.pipe(
    ofType(Actions.ENQUEUE_GET_REF_PRODUCTS_MATCHES_BY_SHOP),
    debounceTime(DEBOUNCE_MILLISECONDS),
    map((action) => withParent(action, getReferenceProductsMatchesByShop())),
  )
}

const getReferenceProductsMatchesByShopEpic = buildEpic(
  Actions.GET_REF_PRODUCTS_MATCHES_BY_SHOP,
  (action, state) => {
    const {
      parent: { requestTotals },
    } = action

    const { page, pageSize } = get(state, [
      "pages",
      "MatchesOverviewPage",
      "pagination",
    ])

    const matchFilters = get(state, [
      "pages",
      "MatchesOverviewPage",
      MATCHES_OVERVIEW_PAGE_FILTERS_KEY,
      MATCHES_OVERVIEW_PAGE_FILTERS_MATCHES_KEY,
    ])

    const referenceFilters = get(state, [
      "pages",
      "MatchesOverviewPage",
      MATCHES_OVERVIEW_PAGE_FILTERS_KEY,
      MATCHES_OVERVIEW_PAGE_FILTERS_REF_PRODUCTS_KEY,
    ])

    const facetReverseLookup = {
      all: "all",
      brand: "brand",
      ean: "ean",
      name: "name",
      ref: "articleNr",
      articlenr: "articlenr",
    }

    const matchStatus = matchFilters.status.filter((x) => x !== STATUS_FLAGGED)

    return APIService.fetchReferenceProductsMatchesByShop({
      page,
      pageSize,
      requestTotals,
      matchCount: matchFilters.count,
      matchCountries: getValuesOfSearchParams(matchFilters.countries),
      matchStatus,
      matchShops: getValuesOfSearchParams(matchFilters.shops),
      referenceProductFamilies: getValuesOfSearchParams(referenceFilters.families),
      referenceProductCategories: getValuesOfSearchParams(referenceFilters.categories),
      referenceProductSearchTerm: referenceFilters.searchTerm,
      referenceProductSearchFacet: facetReverseLookup[referenceFilters.searchFacet],
      referenceProductSearchStrategy: referenceFilters.searchStrategy,
    })
  },
  undefined,
  true,
)

/* -------------------------------------------------------------------------- */
/*                  matches overview page meta (e.g., counts)                 */
/* -------------------------------------------------------------------------- */

const getReferenceProductsMatchesByShopMetaEpic = buildEpic(
  Actions.GET_REF_PRODUCTS_MATCHES_BY_SHOP_META,
  async (_action, _state) => {
    /**
     * Question: how would you do this in RxJS? If `second` request dependend on first,
     * we should use `mergeMap`, but in this case, I do not know.
     */
    const [summary, list] = await Promise.all([
      APIService.getReferenceProductsSummary(),
      APIService.listReferenceProductCategories(),
    ])

    return {
      ...summary,
      categories: list.tree,
    }
  },
  undefined,
  true,
)

/* -------------------------------------------------------------------------- */
/*                                   exports                                  */
/* -------------------------------------------------------------------------- */

export default [
  enqueueGetReferenceProductMatchesByShopEpic,
  getReferenceProductsMatchesByShopEpic,
  getReferenceProductsMatchesByShopMetaEpic,
  triggerGetReferenceProductsMatchesByShopFromUserInteractionEpic,
  triggerGetReferenceProductsMatchesByShopFromHistoryEventEpic,
  triggerMatchesOverviewStoreUpdateFromHistoryEvent,
  triggerResetPageEpic,
  triggerMatchesOverviewSearchParamsUpdateFromStateRehydration,
]
