import { get } from "lodash"
import { useSelector } from "react-redux"
import {
  ASSORTMENT_ANALYSIS_PAGE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_PAGINATION_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

export function useAssortmentAnalysisPagination() {
  return useSelector((state) =>
    get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_PAGINATION_KEY,
    ]),
  )
}
