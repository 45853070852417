import React, { useMemo } from "react"
import mixpanel from "mixpanel-browser"
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material"
import ArrowForward from "@mui/icons-material/ArrowForward"
import AddIcon from "@mui/icons-material/Add"
import { useDispatch } from "react-redux"
import { push } from "connected-react-router"
import { useTranslation } from "react-i18next"
import { useProductGroupsOutline } from "./hooks"
import { LoaderSpinner } from "../ui"
import styles from "./AssortmentAnalysisCategorySelection.module.scss"
import { AssortmentAnalysisExportCategoryButton } from "./AssortmentAnalysisExportCategoryButton"
import { assortmentToolRoutes } from "../../routes/assortment-tool/AssortmentTool"

function Spacer() {
  return <Box sx={{ flexGrow: 1 }} />
}

export function AssortmentAnalysisCategorySelection() {
  const { data: productGroups, isIdle, isFetching, isError } = useProductGroupsOutline()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const categories = useMemo(() => {
    if (!productGroups) {
      return []
    }

    const cat = {}
    productGroups.forEach((group) => {
      const { categoryId: id, categoryName, groupName } = group
      const key = `${id}#${categoryName}`
      if (!cat[key]) {
        cat[key] = { id, categoryName, groups: [groupName] }
      } else {
        cat[key].groups.push(t(groupName))
      }
    })

    return Object.values(cat)
  }, [productGroups, t])

  if (isIdle || isFetching) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <LoaderSpinner />
      </Box>
    )
  }

  if (isError) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Typography variant="h5">{t("Error loading categories")}</Typography>
      </Box>
    )
  }

  return (
    <Box display="flex" flexWrap="wrap" sx={{ p: 2 }}>
      {categories.map(({ id, categoryName, groups }) => (
        <Card
          data-testid="assortment-category-card"
          className={styles["category-card"]}
          key={id}
          variant="outlined"
        >
          <CardActionArea
            onClick={(e) => {
              e.preventDefault()
              mixpanel.track("A3Tool - Category Select", { category: categoryName })
              dispatch(push(`${assortmentToolRoutes.analysis}/${id}`))
            }}
            sx={{
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <CardContent>
              <Box mb={1}>
                <Typography component="h5" fontWeight={400} fontSize="18">
                  {categoryName}
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                {groups.sort().join(", ")}
              </Typography>
            </CardContent>
            <Spacer />
            <CardActions sx={{ p: 2, justifyContent: "space-between", width: "100%" }}>
              <Button
                className={styles["card-link"]}
                size="small"
                startIcon={<ArrowForward />}
                sx={{ fontSize: "14px", fontWeight: 600 }}
                variant="text"
              >
                {t("View")}
              </Button>
              <AssortmentAnalysisExportCategoryButton
                category={id}
                className={styles["card-link"]}
                label={t("Export")}
                sx={{ fontSize: "14px", fontWeight: 600 }}
                variant="text"
              />
            </CardActions>
          </CardActionArea>
        </Card>
      ))}
      <Card className={styles["category-card"]} variant="outlined">
        <CardActionArea
          onClick={(e) => {
            e.preventDefault()
            mixpanel.track("A3Tool - Category Select", {
              category: "New Category Request",
            })
            window.location.href =
              "mailto:support@daltix.com?subject=New product category request"
          }}
          sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
        >
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <AddIcon color="primary" sx={{ fontSize: "64px" }} />
              <Typography color="primary" fontWeight={600} variant="body1">
                {t("Add category")}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  )
}
